import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import TextField2 from 'components/TextField2';
import formStyles, {
  gridStyle, Row, RowInner, ColCard, Fills
} from 'utils/formStyles';
import Checkbox2 from 'components/Checkbox2';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { getService } from 'reducers/service';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import FormDivider from 'components/FormDivider';
import Autocomplete2 from 'components/Autocomplete2';

const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  item: gridStyle(200, 320),
  btn: {
    marginTop: theme.spacing(3),
    display: 'block',
    marginRight: 0,
    marginLeft: 'auto',
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  title: {
    width: 300
  }
}));

function AgencyCad(props) {
  const { agencyId, dictionary } = props;
  const { PartyRelationships, Dispositions } = dictionary;
  const classes = useStyles();
  const service = getService('settings-agency-cad');
  const [routable, setRoutable] = useState(false);
  const [routeOnEventSave, setRouteOnEventSave] = useState(false);
  const [showEventTimes, setShowEventTimes] = useState(false);
  const [printEvent, setPrintEvent] = useState(false);
  const [dispositionRequired, setDispositionRequired] = useState(false);
  const [mergedDispReq, setMergedDispReq] = useState(false);
  const [notifyRequired, setNotifyRequired] = useState(false);
  const [officerRequired, setOfficerRequired] = useState(false);
  const [mergedCode, setMergedCode] = useState(null);
  const [notifyCode, setNotifyCode] = useState(null);
  const [officerCode, setOfficerCode] = useState(null);
  const [caseId, setCaseId] = useState('Routed');
  const [firehouseEnabled, setFirehouseEnabled] = useState(false);
  const [autoGenerate, setAutoGenerate] = useState(false);
  const [firehousePath, setFirehousePath] = useState('');
  const [MultipleCaseID, setMultipleCaseID] = useState(false);
  const [UserCanEnterCaseID, setUserCanEnterCaseID] = useState(false);
  const [cutIncNumber, setCutIncNumber] = useState(false);

  useEffect(() => {
    search();
  }, []);

  const search = async () => {
    const result = await service.get(agencyId);
    renderInitial(result);
  }

  const renderInitial = (data) => {
    for (const row of data) {
      const { Path } = row;
      switch (true) {
        case Path.includes('.CAD.Event.Routable'):
          setRoutable(row.ValueBoolean || false);
          break;
        case Path.includes('.CAD.Event.AutoRoute'):
          setRouteOnEventSave(row.ValueBoolean || false);
          break;
        case Path.includes('.CAD.Event.ShowTimes'):
          setShowEventTimes(row.ValueBoolean || false);
          break;
        case Path.includes('.CAD.Event.Print'):
          setPrintEvent(row.ValueBoolean || false);
          break;
        case Path.includes('.CAD.Event.RequireDisposition'):
          setDispositionRequired(row.ValueBoolean || false);
          break;
        case Path.includes('.CAD.Event.RequireMergedDisposition'):
          setMergedDispReq(row.ValueBoolean || false);
          break;
        case Path.includes('.CAD.Event.RequireNotify.Code'):
          setNotifyCode(PartyRelationships?.find(rel => rel.Code === row.ValueString) || null);
          break;
        case Path.includes('.CAD.Event.RequireOfficer.Code'):
          setOfficerCode(PartyRelationships?.find(rel => rel.Code === row.ValueString) || null);
          break;
        case Path.includes('.CAD.Event.RequireNotify'):
          setNotifyRequired(row.ValueBoolean || false);
          break;
        case Path.includes('.CAD.Event.RequireOfficer'):
          setOfficerRequired(row.ValueBoolean || false);
          break;
        case Path.includes('.CAD.Event.FireHouseEnabled'):
          setFirehouseEnabled(row.ValueBoolean || false);
          break;
        case Path.includes('.CAD.Event.FireHouseExportOnEventClose'):
          setAutoGenerate(row.ValueBoolean || false);
          break;
        case Path.includes('.CAD.Event.FireHouseCutIncidentNumber'):
          setCutIncNumber(row.ValueBoolean || false);
          break;
        case Path.includes('.CAD.Event.DefaultMergedDispositionCode'):
          setMergedCode(Dispositions?.find(disp => disp.Code === row.ValueString) || null);
          break;
        case Path.includes('.CAD.Event.SetCaseID'):
          setCaseId(row.ValueString || '');
          break;
        case Path.includes('.CAD.SetPath.FireHouseExport'):
          setFirehousePath(row.ValueString || '');
          break;
        case Path.includes('.CAD.Event.MultipleCaseID'):
          setMultipleCaseID(row.ValueBoolean || false);
          break;
        case Path.includes('.CAD.Event.UserCanEnterCaseID'):
          setUserCanEnterCaseID(row.ValueBoolean || false);
          break;
      }
    }
  }

  const handleSave = async () => {
    const saveObj = formatSaveData();
    try {
      await service.create(saveObj);
      props.notify("Agency CAD Settings Saved.", "success");
    }
    catch (err) {
      props.handleError(err, 'Error Saving Agency CAD Settings.');
    }
  }

  const formatSaveData = () => {
    return (
      [
        {
          Path: `${agencyId}.CAD.Event.Routable`,
          CDRKey: 'CAD Event',
          ValueBoolean: routable
        },
        {
          Path: `${agencyId}.CAD.Event.AutoRoute`,
          CDRKey: 'CAD Event',
          ValueBoolean: routeOnEventSave
        },
        {
          Path: `${agencyId}.CAD.Event.ShowTimes`,
          CDRKey: 'CAD Event',
          ValueBoolean: showEventTimes
        },
        {
          Path: `${agencyId}.CAD.Event.Print`,
          CDRKey: 'CAD Event',
          ValueBoolean: printEvent
        },
        {
          Path: `${agencyId}.CAD.Event.RequireDisposition`,
          CDRKey: 'CAD Event',
          ValueBoolean: dispositionRequired
        },
        {
          Path: `${agencyId}.CAD.Event.RequireMergedDisposition`,
          CDRKey: 'CAD Paths',
          ValueBoolean: mergedDispReq
        },
        {
          Path: `${agencyId}.CAD.Event.RequireNotify`,
          CDRKey: 'CAD Event',
          ValueBoolean: notifyRequired
        },
        {
          Path: `${agencyId}.CAD.Event.RequireOfficer`,
          CDRKey: 'CAD Event',
          ValueBoolean: officerRequired
        },
        {
          Path: `${agencyId}.CAD.Event.FireHouseEnabled`,
          CDRKey: 'CAD Event',
          ValueBoolean: firehouseEnabled
        },
        {
          Path: `${agencyId}.CAD.Event.FireHouseExportOnEventClose`,
          CDRKey: 'CAD Event',
          ValueBoolean: autoGenerate
        },
        {
          Path: `${agencyId}.CAD.Event.FireHouseCutIncidentNumber`,
          CDRKey: 'CAD Event',
          ValueBoolean: cutIncNumber
        },
        {
          Path: `${agencyId}.CAD.Event.DefaultMergedDispositionCode`,
          CDRKey: 'CAD Paths',
          ValueString: mergedCode?.Code || null
        },
        {
          Path: `${agencyId}.CAD.Event.RequireNotify.Code`,
          CDRKey: 'CAD Event',
          ValueString: notifyCode?.Code || null
        },
        {
          Path: `${agencyId}.CAD.Event.RequireOfficer.Code`,
          CDRKey: 'CAD Event',
          ValueString: officerCode?.Code || null
        },
        {
          Path: `${agencyId}.CAD.Event.SetCaseID`,
          CDRKey: 'CAD Event Case ID',
          ValueString: caseId
        },
        {
          Path: `${agencyId}.CAD.SetPath.FireHouseExport`,
          CDRKey: 'CAD Paths',
          ValueString: firehousePath
        },
        {
          Path: `${agencyId}.CAD.Event.MultipleCaseID`,
          CDRKey: 'CAD Event',
          ValueBoolean: MultipleCaseID
        },
        {
          Path: `${agencyId}.CAD.Event.UserCanEnterCaseID`,
          CDRKey: 'CAD Event',
          ValueBoolean: UserCanEnterCaseID
        },
      ]
    )
  }

  const renderEvent = () => {
    return (
      <div>
        <FormDivider title="Event" />
        <Row>
          <ColCard minWidth={500}>
            {renderRoutable()}
            {renderRouteOnEventSave()}
            {renderShowEventTimes()}
            {renderPrintEvent()}
            {renderDispositionReq()}
          </ColCard>
          <ColCard minWidth={500}>
            <RowInner>
              {renderMergedDispReq()}
              {renderMergedCode()}
            </RowInner>
            <RowInner>
              {renderNotifyRequired()}
              {renderNotifyCode()}
            </RowInner>
            <RowInner>
              {renderOfficerRequired()}
              {renderOfficerCode()}
            </RowInner>
            <RowInner>
              {renderMultipleCaseID()}
            </RowInner>
            <RowInner>
              {renderUserCanEnterEventID()}
            </RowInner>
          </ColCard>
        </Row>
      </div>
    )
  }

  const renderCaseID = () => {
    const handleChange = (e) => setCaseId(e.target.value);
    return (
      <div>
        <FormDivider title="Case ID" />
        <RowInner>
          <FormControl component="fieldset">
            <RadioGroup value={caseId} onChange={handleChange}>
              <FormControlLabel value="Routed" control={<Radio />} label="Routed" />
              <FormControlLabel value="Dispatched" control={<Radio />} label="Dispatched" />
              <FormControlLabel value="Acknowledged" control={<Radio />} label="Acknowledged" />
              <FormControlLabel value="Manual" control={<Radio />} label="Manual" />
            </RadioGroup>
          </FormControl>
        </RowInner>
      </div>
    )
  }

  const renderFirehouse = () => {
    return (
      <>
        <FormDivider title="Fire RMS Interface" />
        {renderInterfaceEnabled()}
        {renderAutogen()}
        {renderCutIncidentNumber()}
        {renderPath()}
      </>
    )
  }

  const renderSaveBtn = () => {
    return (
      <div>
        <Button
          color="primary"
          variant="contained"
          onClick={handleSave}
          className="mt-4"
        >
          <SaveIcon /> Save
        </Button>
      </div>
    )
  }

  const renderInterfaceEnabled = () => {
    const onChange = () => setFirehouseEnabled(!firehouseEnabled);
    return (
      <div>
        <Checkbox2
          checked={firehouseEnabled}
          onChange={onChange}
          label="Fire RMS Interface Enabled?"
        />
      </div>
    );
  }
  const renderAutogen = () => {
    const onChange = () => setAutoGenerate(!autoGenerate);
    return (
      <div>
        <Checkbox2
          checked={autoGenerate}
          onChange={onChange}
          label="AutoGenerate Export Files When An Event Is Closed?"
        />
      </div>
    );
  }

  const renderCutIncidentNumber = () => {
    const onChange = () => setCutIncNumber(!cutIncNumber);
    return (
      <div>
        <Checkbox2
          checked={cutIncNumber}
          onChange={onChange}
          label="Export 7 characters Incident Number?"
        />
      </div>
    );
  }

  const renderPath = () => {
    const onChange = (ev, val) => setFirehousePath(val);
    return (
      <TextField2
        fullwidth
        className={classes.item}
        label="Fire RMS Output Path"
        value={firehousePath}
        onChange={onChange}
      />
    );
  }

  const renderRoutable = () => {
    const onChange = () => setRoutable(!routable);
    return (
      <div>
        <Checkbox2
          checked={routable}
          onChange={onChange}
          label="Routable Agency"
        />
      </div>
    );
  }
  const renderRouteOnEventSave = () => {
    const onChange = () => setRouteOnEventSave(!routeOnEventSave);
    return (
      <div>
        <Checkbox2
          checked={routeOnEventSave}
          onChange={onChange}
          label="Route On Event Save"
        />
      </div>
    );
  }
  const renderShowEventTimes = () => {
    const onChange = () => setShowEventTimes(!showEventTimes);
    return (
      <div>
        <Checkbox2
          checked={showEventTimes}
          onChange={onChange}
          label="Show Event Times On Closing"
        />
      </div>
    );
  }
  const renderPrintEvent = () => {
    const onChange = () => setPrintEvent(!printEvent);
    return (
      <div>
        <Checkbox2
          checked={printEvent}
          onChange={onChange}
          label="Print Event On Closing"
        />
      </div>
    );
  }
  const renderDispositionReq = () => {
    const onChange = () => setDispositionRequired(!dispositionRequired);
    return (
      <div>
        <Checkbox2
          checked={dispositionRequired}
          onChange={onChange}
          label="Disposition Required"
        />
      </div>
    );
  }
  const renderMergedDispReq = () => {
    const onChange = () => setMergedDispReq(!mergedDispReq);
    return (
      <div className={classes.title}>
        <Checkbox2
          checked={mergedDispReq}
          onChange={onChange}
          label="Merged Disposition Required"
        />
      </div>
    );
  }
  const renderNotifyRequired = () => {
    const onChange = () => setNotifyRequired(!notifyRequired);
    return (
      <div className={classes.title}>
        <Checkbox2
          checked={notifyRequired}
          onChange={onChange}
          label="Notify Required"
        />
      </div>
    );
  }
  const renderOfficerRequired = () => {
    const onChange = () => setOfficerRequired(!officerRequired);
    return (
      <div className={classes.title}>
        <Checkbox2
          checked={officerRequired}
          onChange={onChange}
          label="Officer Required"
        />
      </div>
    );
  }

  const renderMultipleCaseID = () => {
    const onChange = () => setMultipleCaseID(!MultipleCaseID);
    return (
      <div>
        <Checkbox2
          checked={MultipleCaseID}
          onChange={onChange}
          label="Multiple Case ID"
        />
      </div>
    );
  }

  const renderUserCanEnterEventID = () => {
    const onChange = () => setUserCanEnterCaseID(!UserCanEnterCaseID);
    return (
      <div>
        <Checkbox2
          checked={UserCanEnterCaseID}
          onChange={onChange}
          label="User Can Enter CaseID"
        />
      </div>
    );
  }

  const renderMergedCode = () => {
    return (
      <Autocomplete2
        options={Dispositions}
        className={classes.item}
        onChange={(ev, val) => setMergedCode(val)}
        value={mergedCode}
        label="Merged Code"
        disabled={!mergedDispReq}
        renderOption={option => `${option.Code} - ${option.Description} - ${option.AgencyID}`}
      />
    )
  }
  const renderNotifyCode = () => {
    return (
      <Autocomplete2
        options={PartyRelationships}
        className={classes.item}
        onChange={(ev, val) => setNotifyCode(val)}
        value={notifyCode}
        label="Notify Party Code"
        disabled={!notifyRequired}
      />
    )
  }
  const renderOfficerCode = () => {
    return (
      <Autocomplete2
        options={PartyRelationships}
        className={classes.item}
        onChange={(ev, val) => setOfficerCode(val)}
        value={officerCode}
        label="Officer Party Code"
        disabled={!officerRequired}
      />
    )
  }
  return (
    <div className={classes.wrap}>
      {renderEvent()}
      <Row>
        <ColCard minWidth={500}>
          {renderCaseID()}
        </ColCard>
        <ColCard minWidth={500}>
          {renderFirehouse()}
        </ColCard>
      </Row>
      {renderSaveBtn()}
    </div>
  )
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  handleError, notify,
})(AgencyCad);
