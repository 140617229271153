import React, { useState, useEffect, useCallback, useRef, Fragment } from 'react';
import { connect } from 'react-redux';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { GoogleMap, Polygon, OverlayView } from '@react-google-maps/api';
import { mapNight, mapDay } from '../../config/configureMap';
import MarkersEvents from '../../components/Map/MarkersEvents';
import MarkersUnits from '../../components/Map/MarkersUnits';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { DrawingManager } from '@react-google-maps/api';
import MenuList from '@material-ui/core/MenuList';
import MenuItem from '@material-ui/core/MenuItem';
import Card from '@material-ui/core/Card';
import AddIcon from '@material-ui/icons/Add';
import ImportExportIcon from '@material-ui/icons/ImportExport';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import FormatSizeIcon from '@material-ui/icons/FormatSize';
import FormatShapesIcon from '@material-ui/icons/FormatShapes';
import IconButton from '@material-ui/core/IconButton';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import DeleteIcon from '@material-ui/icons/Delete';
import ClearPathIcon from '@material-ui/icons/LayersClear';
import GeofenceIcon from '@material-ui/icons/Room';
import NoGeofenceIcon from '@material-ui/icons/LocationOff';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import TextField from '@material-ui/core/TextField';
import BackupIcon from '@material-ui/icons/Backup';
import Tooltip from '../../components/Tooltip';
import settings from '../../config/settings';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Autocomplete } from '@material-ui/lab';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import { sortObjArr } from '../../utils/functions';
import {
  getPathCenter,
  getPathFromPolygon,
  pathToStr,
  alignToPaths,
  calculateDistance,
  removeDuplicatePoints,
} from '../../utils/mapFunctions';
import { saveZone, getZones, removeZone, updateZones } from '../../reducers/ZonesReducer';
import { saveESN, getESNs, removeESN } from '../../reducers/EsnReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { notify } from '../../reducers/NotifierReducer';
import UnitIcon from '@material-ui/icons/Group';
import CenterMapIcon from '@material-ui/icons/GpsFixed';
import EventIcon from '@material-ui/icons/LocationOn';
import { handleError } from '../../reducers/ErrorReducer';
import { importEsns, importZones } from '../../reducers/DialogsReducer';
import Content from '../Content';

const panelWidth = 300;

const useStyles = makeStyles((theme) => ({
  map: {
    position: 'absolute',
    left: panelWidth,
    top: 0,
    right: 0,
    bottom: 0,
  },
  label: {
    padding: 0,
    marginLeft: -5,
    marginTop: -6,
    color: 'blue',
    fontWeight: 600,
  },
  toolbar: {
    position: 'fixed',
    top: theme.spacing(1),
    left: '50%',
    transform: 'translateX(-50%)',
    padding: theme.spacing(1),
    '& button': {
      padding: theme.spacing(0.5),
      borderRadius: theme.shape.borderRadius,
      background: 'none',
      marginLeft: theme.spacing(0.5),
      border: 'none',
      color: theme.colors.grey3,
      '&.active': {
        color: theme.colors.grey6,
      },
      '&:first-child': {
        marginLeft: 0,
      },
    },
  },
  panel: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: panelWidth,
    height: `calc(100vh - ${settings.mainNavHeight + theme.spacing(3)}px)`,
  },
  actionBar: {
    margin: theme.spacing(1),
    textAlign: 'right',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  form: {
    padding: `0 ${theme.spacing(2)}px`,
  },
  textField: {
    width: '100%',
    margin: `0 0 ${theme.spacing(1)}px`,
  },
  geofenceList: {
    position: 'absolute',
    left: 0,
    right: 0,
    top: 204,
    bottom: 0,
    overflow: 'auto',
    height: 'auto',
  },
  wrap: {
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  activeMenu: {
    position: 'relative',
    background: theme.colors.grey1,
    '&:hover': {
      background: theme.colors.grey1,
    },
    '& $menuActions': {
      display: 'block',
      background: theme.colors.grey1,
    },
    '& svg': {
      opacity: 0.75,
      marginRight: theme.spacing(1),
    },
  },
  menuItem: {
    position: 'relative',
    '& svg': {
      opacity: 0.75,
      marginRight: theme.spacing(1),
    },
  },
  menuActions: {
    position: 'absolute',
    top: -4,
    right: 0,
    display: 'none',
  },
  pointMenu: {
    background: '#fff',
    border: '1px solid #666',
    borderRadius: 3,
    zIndex: 9999999,
    marginTop: 10,
    marginLeft: -30,
    color: '#666',
    fontSize: 16,
    fontWeight: 400,
    overflow: 'hidden',
    '& > div': {
      fontSize: 12,
      padding: '3px 8px',
      transition: 'backgroundColor 0.3s',
    },
    '& > div:hover': {
      backgroundColor: '#eee',
    },
  },
}));

const mapContainerStyle = { width: '100%', height: '100%' };

const defaultPathOptions = {
  fillColor: 'red',
  fillOpacity: 0.2,
  strokeColor: 'red',
  strokeOpacity: 1,
  strokeWeight: 2,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const geofenceOptions = {
  fillColor: 'blue',
  fillOpacity: 0.1,
  strokeColor: 'blue',
  strokeOpacity: 1,
  strokeWeight: 1,
  clickable: true,
  draggable: false,
  editable: false,
  geodesic: false,
  zIndex: 1,
};

const drawingOptions = {
  drawingControl: false,
  drawingMode: null,
};

const center = { lat: 30.45, lng: -91.11 };

const Types = settings.geofenceTypes;

function GeofenceDesigner(props) {
  const { mapOptions, themeMode, events, units, dictionary, zoneDataChanged } = props;
  const classes = useStyles();
  const [geofences, setGeofences] = useState([]);
  const [ZoneCodes, setZoneCodes] = useState([]);
  const [filteredGeofences, setFilteredGeofences] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [path, setPath] = useState([]);
  const [Type, setType] = useState(Types[1]);
  const [pathOptions, setPathOptions] = useState(defaultPathOptions);
  const [mode, setMode] = useState('preview'); // previev, select, new, edit
  const [ZoneCode, setZoneCode] = useState('');
  const [Description, setDescription] = useState('');
  const [Agency, setAgency] = useState(null);
  const [Agencies, setAgencies] = useState([]);
  const [ROWGUID, setROWGUID] = useState(null);
  const [formValid, setFormValid] = useState(false);
  const [filter, setFilter] = useState('');
  const [showGeofences, setShowGeofences] = useState(true);
  const [showLabels, setShowLabels] = useState(true);
  const [geofencesSelectable, setGeofencesSelectable] = useState(true);
  const [AttachToEvent, setAttachToEvent] = useState(true);
  const [autoAlign, setAutoAlign] = useState(true);
  const [showEvents, setShowEvents] = useState(true);
  const [showUnits, setShowUnits] = useState(true);
  const [pointMnu, setPointMnu] = useState(null);
  const [ems, setEms] = useState(null);
  const [fire, setFire] = useState(null);
  const [law, setLaw] = useState(null);
  const [lawAgencies, setLawAgencies] = useState([]);
  const [fireAgenices, setFireAgencies] = useState([]);
  const [emsAgencies, setEmsAgencies] = useState([]);
  const mapRef = useRef(null);
  const drawingMgrRef = useRef(null);
  const polygonRef = useRef(null);
  const pointEvRef = useRef(null);
  mapOptions.styles = themeMode === 'day' ? mapDay : mapNight;

  useEffect(() => {
    getAllGeofences();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (Type && Type.Code === 'Zone' && !Agency) {
      deselect();
      setFilteredGeofences([]);
      setGeofences([]);
      return;
    }
    deselect();
    getRecords();
    // elint-disable-next-line
  }, [Agency, Type, zoneDataChanged]);

  useEffect(() => {
    if (!dictionary || !dictionary.Agencies) return;
    const { Agencies } = dictionary;
    setAgencies(
      Agencies.map((agency) => {
        return { Code: agency.AgencyID, Description: agency.Description };
      })
    );
    setLawAgencies(
      Agencies.filter((agency) => agency.AgencyType === 1).map((agency) => agency.AgencyID)
    );
    setFireAgencies(
      Agencies.filter((agency) => agency.AgencyType === 2).map((agency) => agency.AgencyID)
    );
    setEmsAgencies(
      Agencies.filter((agency) => agency.AgencyType === 4).map((agency) => agency.AgencyID)
    );
    // eslint-disable-next-line
  }, [dictionary]);

  useEffect(() => {
    // New/Edit geofence form validation
    validate();
    // eslint-disable-next-line
  }, [ZoneCode, path, Agency, ems, fire, law]);

  useEffect(() => {
    // Filter geofences
    const lowerFilter = filter.toLowerCase();
    const AgencyID = Agency ? Agency.Code : null;
    const filtered = geofences.filter((g) => {
      let filter =
        g.ZoneCode.toLocaleLowerCase().indexOf(lowerFilter) !== -1 ||
        g.Description.toLowerCase().indexOf(lowerFilter) !== -1;
      if (Type && Type.Code === 'ESN' && AgencyID) {
        const { EMSResponseAgencyID, FireResponseAgencyID, LawEnforcementAgencyID } = g;
        filter =
          filter &&
          (EMSResponseAgencyID === AgencyID ||
            FireResponseAgencyID === AgencyID ||
            LawEnforcementAgencyID === AgencyID);
      }
      return filter;
    });
    const sorted = sortObjArr(filtered, 'ZoneCode', 'ASC');
    setFilteredGeofences(sorted);
    // eslint-disable-next-line
  }, [geofences, filter, Agency]);

  const onMapLoad = useCallback((map) => {
    mapRef.current = map;
  }, []);

  const refreshZones = () => {
    deselect();
    setFilteredGeofences([]);
    setGeofences([]);
    setZoneCodes([]);
    setROWGUID(null);
    setFilteredGeofences([]);
    setSelectedId(null);
    setPath([]);
    setMode('preview');
    setZoneCode('');
    setDescription('');
    setFormValid(false);
    getRecords();
    getAllGeofences();
    props.updateZones();
  }

  const getAllGeofences = async () => {
    try {
      const allZones = await getZones();
      const zoneCodes = allZones.map((zone) => zone.ZoneCode);
      setZoneCodes(zoneCodes);
    } catch (err) {
      props.handleError(err);
    }
  }

  const getRecords = () => {
    const fn = Type && Type.Code === 'Zone' ? getZones : getESNs;
    const agency = Agency ? Agency.Code : null;
    fn(agency)
      .then((result) => {
        if (Type && Type.Code === 'ESN') {
          result = result.map((row) => {
            return { ...row, ZoneCode: row.ESN };
          });
        }
        setGeofences(result);
      })
      .catch((error) => props.handleError(error, 'There was a problem with receiving geofences'))
      .finally(() => props.hideSpinner());
  };

  const centerToCurrent = () => {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        mapRef.current.panTo({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
      },
      (err) => {
        console.log('err', err);
      }
    );
  };

  function handleCenterChanged() {
    if (!mapRef.current) return;
  }

  const onLoadDrawingManager = (drawingManager) => {
    drawingMgrRef.current = drawingManager;
  };

  const handlePolygonComplete = (polygon) => {
    const path = getPathFromPolygon(polygon);
    drawingMgrRef.current.setDrawingMode(null);
    polygon.setMap(null);
    setPathOptions({ ...pathOptions, editable: true });
    if (autoAlign) {
      alignPoints(path);
    } else {
      setPath(path);
    }
  };

  const renderGeofences = () => {
    return geofences
      .filter((geofence) => Boolean(geofence.path))
      .map((g) => (
        <Fragment key={g.ptsGeofenceID}>
          {showLabels && (
            <OverlayView mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET} position={g.center}>
              <div className={classes.label}>{g.ZoneCode}</div>
            </OverlayView>
          )}
          {g.ROWGUID !== selectedId && showGeofences && (
            <Polygon
              paths={g.path}
              options={geofenceOptions}
              onClick={() => geofencesSelectable && handleItemClick(g.ROWGUID, false)}
            />
          )}
        </Fragment>
      ));
  };

  const handleItemClick = (ROWGUID, center = true) => {
    closePointMenu();
    if (mode === 'new' || mode == 'edit') return;
    setPathOptions({ ...pathOptions, editable: false });
    const currentGeofence = geofences.find((g) => g.ROWGUID === ROWGUID);
    if (selectedId === ROWGUID) {
      deselect();
    } else {
      setMode('select');
      if (!currentGeofence) return;
      setSelectedId(ROWGUID);
      setROWGUID(currentGeofence.ROWGUID);
      if (currentGeofence.ptsGeofenceID) {
        setPath(currentGeofence.path ? currentGeofence.path : []);
        setAttachToEvent(currentGeofence.AttachToEvent);
        center &&
          currentGeofence.center &&
          mapRef.current.panTo({
            lat: currentGeofence.center.lat,
            lng: currentGeofence.center.lng,
          });
      } else {
        setPath(null);
      }
    }
  };

  const deselect = () => {
    setMode('preview');
    setSelectedId(null);
    setPath([]);
    setZoneCode('');
    setDescription('');
    setAttachToEvent(true);
    setROWGUID(null);
  };

  const startDrawing = () => {
    setSelectedId(null);
    setPath([]);
    setMode('new');
    setROWGUID(null);
    drawingMgrRef.current.setDrawingMode('polygon');
  };

  const getCurrentGeofence = () => geofences.find((g) => g.ROWGUID === ROWGUID);

  const isZoneCodeUnique = () => {
    if (Type.Code === 'Zone') {
      if (mode === 'edit') {
        const currentGeofence = getCurrentGeofence();
        const currentZoneCode = currentGeofence.ZoneCode;
        if (ZoneCode === currentZoneCode) return true;
      }
      return ZoneCodes.indexOf(ZoneCode) === -1;
    } else {
      if (mode === 'edit') {
        const currentGeofence = getCurrentGeofence();
        const currentZoneCode = currentGeofence.ESN;
        if (ZoneCode === currentZoneCode) return true;
      }
      return geofences.findIndex((g) => g.ESN === ZoneCode) === -1;
    }
  }

  const validate = () => {
    if (Type.Code === 'Zone') {
      const ZoneCodeUnique = isZoneCodeUnique();
      if (ZoneCode !== '' && Agency && ZoneCodeUnique) {
        !formValid && setFormValid(true);
      } else {
        formValid && setFormValid(false);
      }
    } else {
      const ZoneCodeUnique = isZoneCodeUnique();
      if (ZoneCode !== '' && ZoneCodeUnique && law && ems && fire) {
        !formValid && setFormValid(true);
      } else {
        formValid && setFormValid(false);
      }
    }
  };

  const save = () => {
    if (!formValid) return;
    props.showSpinner();
    let newPath = getPathFromPolygon(polygonRef.current);
    const currentGeofence = getCurrentGeofence();
    const ptsGeofenceID = mode === 'edit' ? currentGeofence.ptsGeofenceID : null;
    const geofence = {
      ZoneCode,
      AttachToEvent,
      Description,
      ptsGeofenceID,
      center: null,
      path: null,
      ROWGUID,
    };
    let SurfaceText = null;
    if (newPath) {
      SurfaceText = pathToStr(newPath);
      geofence.path = [...newPath];
      geofence.center = getPathCenter(newPath);
    }

    let newGeofence;
    if (Type.Code === 'Zone') {
      newGeofence = {
        Code: Type.Code,
        Description,
        ZoneCode,
        AgencyID: Agency?.Code || null,
        SurfaceText,
        ptsGeofenceID,
        AttachToEvent,
        ROWGUID,
      };
    } else {
      newGeofence = {
        ESN: ZoneCode,
        SurfaceText,
        ROWGUID,
        law,
        fire,
        ems,
      };
    }

    const fn = Type && Type.Code === 'Zone' ? saveZone : saveESN;
    fn(newGeofence)
      .then(() => {
        setPathOptions({ ...pathOptions, editable: false });
        refreshZones();
        setEms(null);
        setFire(null);
        setLaw(null);
      })
      .catch(props.handleError)
      .finally(() => props.hideSpinner());
  };

  const deleteFence = () => {
    if (!window.confirm('Are you sure you want to delete this path?')) return;
    props.showSpinner();
    const zone = geofences.find((g) => g.ROWGUID === selectedId);
    const fn = Type.Code === 'Zone' ? removeZone : removeESN;
    fn(zone)
      .then(() => {
        setGeofences(geofences.filter((g) => g.ROWGUID !== selectedId));
        Type.Code === 'Zone' && props.updateZones();
      })
      .catch((error) => props.handleError(error, 'Error, problem with deleting geofence'))
      .finally(() => props.hideSpinner());
    deselect();
    getAllGeofences();
  };

  const clearPath = () => {
    drawingMgrRef.current.setDrawingMode(null);
    drawingMgrRef.current.setDrawingMode('polygon');
    setPath([]);
  };

  const cancel = () => {
    deselect();
    drawingMgrRef.current.setDrawingMode(null);
    setPathOptions({ ...pathOptions, editable: false });
    setPath([]);
  };

  const editPath = () => {
    const geofence = geofences.find((g) => g.ROWGUID === selectedId);
    setPathOptions({ ...pathOptions, editable: true });
    setMode('edit');
    setZoneCode(geofence.ZoneCode);
    setDescription(geofence.Description);
    if (Type.Code === 'ESN') {
      const { EMSResponseAgencyID, FireResponseAgencyID, LawEnforcementAgencyID } = geofence;
      setEms(EMSResponseAgencyID);
      setFire(FireResponseAgencyID);
      setLaw(LawEnforcementAgencyID);
    }
    if (!geofence.ptsGeofenceID) clearPath();
  };

  const deletePoint = () => {
    const { _this, event } = pointEvRef.current;
    if (event.path != null && event.vertex != null) {
      const path = _this.getPaths().getAt(event.path);
      path.removeAt(event.vertex);
    }
  }

  const alignPoint = () => {
    const maxDistance = 75;
    const { event } = pointEvRef.current;
    if (event.path != null && event.vertex != null) {
      const pointNo = event.vertex;
      const editedPath = getPathFromPolygon(polygonRef.current);
      const point = editedPath[pointNo];
      const filteredGeofences = geofences.filter((g) => g.ROWGUID !== selectedId && g.path);
      let closestPoint = null;
      filteredGeofences.forEach((geofence) => {
        geofence.path.forEach((p) => {
          const distance = calculateDistance(point, p);
          if ((!closestPoint || closestPoint.distance > distance) && distance < maxDistance) {
            closestPoint = { ...p, distance };
          }
        });
      });
      if (closestPoint) editedPath[pointNo] = closestPoint;
      setPath(editedPath);
    }
  }

  const onPolygonLoad = (polygon) => {
    polygonRef.current = polygon;
    polygon.addListener('rightclick', function (event) {
      if (event.path != null && event.vertex != null) {
        const path = this.getPaths().getAt(event.path);
        const lat = event.latLng.lat();
        const lng = event.latLng.lng();
        setPointMnu({ lat, lng });
        pointEvRef.current = { _this: this, event }
      }
    })
  };

  const renderPointMenu = () => {
    return (
      <OverlayView
        mapPaneName={OverlayView.OVERLAY_MOUSE_TARGET}
        position={pointMnu}
      >
        <div className={classes.pointMenu}>
          <div onClick={deletePoint}>Delete</div>
          <div onClick={alignPoint}>Align point</div>
        </div>
      </OverlayView>
    );
  }

  const closePointMenu = () => {
    setTimeout(() => pointMnu && setPointMnu(null), 0);
  }

  const alignPoints = (path) => {
    const editedPath = path && path.length > 0 ? path : getPathFromPolygon(polygonRef.current);
    const filteredGeofences = geofences.filter((g) => g.ROWGUID !== selectedId);
    const newPath = alignToPaths(editedPath, filteredGeofences);
    setPath(removeDuplicatePoints(newPath));
  };

  const importEsns = () => {
    props.importEsns({ Type, Agency });
  };

  const renderEsnFields = () => {
    return (
      <>
        <Autocomplete
          className={classes.textField}
          options={lawAgencies}
          value={law}
          onChange={(ev, val) => setLaw(val)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Law Agency"
              variant="outlined"
              size="small"
            />
          )}
        />
        <Autocomplete
          className={classes.textField}
          options={fireAgenices}
          value={fire}
          onChange={(ev, fire) => setFire(fire)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Fire Agency"
              variant="outlined"
              size="small"
            />
          )}
        />
        <Autocomplete
          className={classes.textField}
          options={emsAgencies}
          value={ems}
          onChange={(ev, ems) => setEms(ems)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="EMS Agency"
              variant="outlined"
              size="small"
            />
          )}
        />
      </>
    );
  }

  const renderActionBar = () => {
    return (
      <div className={classes.actionBar}>
        <div>
          {(mode === 'new' || mode === 'edit') && (
            <Tooltip title="Discard changes">
              <IconButton onClick={cancel}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {mode === 'select' && (
            <Tooltip title="Deselect">
              <IconButton onClick={cancel}>
                <ClearIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
        </div>
        {mode === 'new' && <div>New</div>}
        {mode === 'edit' && <div>Edit</div>}
        <div>
          {(mode === 'new' || mode === 'edit') && (
            <Tooltip title="Clear path">
              <span>
                <IconButton onClick={clearPath} disabled={path.length === 0}>
                  <ClearPathIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {(mode === 'new' || mode === 'edit') && (
            <Tooltip title="Align points">
              <span>
                <IconButton onClick={alignPoints} disabled={path.length === 0}>
                  <FormatShapesIcon />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {(mode === 'new' || mode === 'edit') && (
            <Tooltip title="Save">
              <span>
                <IconButton onClick={save} disabled={!formValid}>
                  <SaveIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {(mode === 'preview' || mode === 'select') && (
            <Tooltip title="Add new">
              <span>
                <IconButton onClick={startDrawing} disabled={(Type.Code === 'Zone' && !Agency)}>
                  <AddIcon fontSize="small" />
                </IconButton>
              </span>
            </Tooltip>
          )}
          {mode === 'select' && (
            <Tooltip title="Edit">
              <IconButton onClick={editPath}>
                <EditIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {mode === 'select' && (
            <Tooltip title="Remove">
              <IconButton onClick={deleteFence}>
                <DeleteIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {mode === 'preview' && Type.Code === 'ESN' && (
            <Tooltip title="Import">
              <IconButton onClick={importEsns}>
                <ImportExportIcon fontSize="small" />
              </IconButton>
            </Tooltip>
          )}
          {mode === 'preview' && Type.Code !== 'ESN' && <Tooltip title="Backup">
            <IconButton onClick={props.importZones}>
              <BackupIcon fontSize="small" />
            </IconButton>
          </Tooltip>}
        </div>
      </div>
    );
  };

  const renderEditForm = () => {
    return (
      <div className={classes.form}>
        <TextField
          className={classes.textField}
          label="Zone Code*"
          variant="outlined"
          size="small"
          value={ZoneCode}
          onChange={(ev) => setZoneCode(ev.target.value)}
          error={!isZoneCodeUnique()}
        />
        {Type.Code === 'Zone' && <TextField
          className={classes.textField}
          label="Description"
          variant="outlined"
          size="small"
          value={Description}
          onChange={(ev) => setDescription(ev.target.value)}
        />}
        {Type.Code === 'ESN' && renderEsnFields()}
        {Type.Code === 'Zone' && <FormControlLabel
          className={clsx(classes.checkbox, classes.textField)}
          control={
            <Checkbox
              checked={AttachToEvent}
              onChange={(ev) => setAttachToEvent(ev.target.checked)}
              className={classes.checkbox}
            />
          }
          label="Attach To Event"
        />}
      </div>
    );
  };

  const renderGeofenceList = () => {
    const style = Type.Code === 'ESN' && (mode === 'edit' || mode === 'new') ? { top: 250 } : undefined;
    return (
      <PerfectScrollbar className={classes.geofenceList} style={style}>
        <MenuList>
          {filteredGeofences.map((geofence) => {
            const { ptsGeofenceID, ZoneCode, Description, ROWGUID } = geofence;
            return (
              <MenuItem
                key={ROWGUID}
                onClick={(ev) => handleItemClick(ROWGUID, true)}
                className={selectedId === ROWGUID ? classes.activeMenu : classes.menuItem}>
                <div className={classes.item} title={Description}>
                  {Boolean(ptsGeofenceID) && <GeofenceIcon />}
                  {!ptsGeofenceID && <NoGeofenceIcon />}
                  <strong>{ZoneCode}</strong>
                  {Description !== '' && <span> - {Description}</span>}
                </div>
              </MenuItem>
            );
          })}
        </MenuList>
      </PerfectScrollbar>
    );
  };

  const renderFilterForm = () => {
    return (
      <div className={classes.form}>
        <CustomAutocomplete
          className={classes.textField}
          label="Type*"
          options={Types}
          value={Type}
          onChange={(val) => setType(val)}
          disableClearable
        />
        <CustomAutocomplete
          className={classes.textField}
          label="Agency*"
          options={Agencies}
          value={Agency}
          onChange={(val) => setAgency(val)}
        />
        <TextField
          className={classes.textField}
          label="Filter"
          variant="outlined"
          size="small"
          value={filter}
          onChange={(ev) => setFilter(ev.target.value)}
          type="search"
        />
      </div>
    );
  };

  const renderPanel = () => {
    return (
      <Card className={classes.panel}>
        {renderActionBar()}
        {(mode === 'new' || mode === 'edit') && renderEditForm()}
        {(mode === 'preview' || mode === 'select') && renderFilterForm()}
        {renderGeofenceList()}
      </Card>
    );
  };

  const renderSelectedPath = () => {
    if (!path) return null;
    return (
      <Polygon
        paths={path}
        options={pathOptions}
        onLoad={onPolygonLoad}
        onClick={closePointMenu}
      />
    )
  };

  const renderDrawingManager = () => {
    return (
      <DrawingManager
        drawingMode="polygon"
        options={drawingOptions}
        onLoad={onLoadDrawingManager}
        onPolygonComplete={handlePolygonComplete}
      />
    );
  };

  const renderToolbar = () => {
    return (
      <Card className={classes.toolbar}>
        <Tooltip title="Show/hide all geofences">
          <button
            onClick={() => setShowGeofences(!showGeofences)}
            className={showGeofences ? 'active' : ''}>
            <VisibilityIcon />
          </button>
        </Tooltip>
        <Tooltip title="Show geofence labels">
          <button onClick={() => setShowLabels(!showLabels)} className={showLabels ? 'active' : ''}>
            <FormatSizeIcon />
          </button>
        </Tooltip>
        <Tooltip title="Auto align points">
          <button onClick={() => setAutoAlign(!autoAlign)} className={autoAlign ? 'active' : ''}>
            <FormatShapesIcon />
          </button>
        </Tooltip>
        <Tooltip title="Make geofences selectable">
          <button
            onClick={() => setGeofencesSelectable(!geofencesSelectable)}
            className={geofencesSelectable ? 'active' : ''}>
            <TouchAppIcon />
          </button>
        </Tooltip>
        <Tooltip title="Show events">
          <button onClick={() => setShowEvents(!showEvents)} className={showEvents ? 'active' : ''}>
            <EventIcon />
          </button>
        </Tooltip>
        <Tooltip title="Show units">
          <button onClick={() => setShowUnits(!showUnits)} className={showUnits ? 'active' : ''}>
            <UnitIcon />
          </button>
        </Tooltip>
        <Tooltip title="Center map to current location">
          <button onClick={centerToCurrent} className={'active'}>
            <CenterMapIcon />
          </button>
        </Tooltip>
      </Card>
    );
  };

  return (
    <Content>
      <div className={classes.wrap}>
        <div className={classes.map}>
          <GoogleMap
            mapContainerStyle={mapContainerStyle}
            zoom={15}
            center={center}
            options={mapOptions}
            key={themeMode}
            onLoad={onMapLoad}
            onCenterChanged={handleCenterChanged}
            onClick={closePointMenu}
            className={classes.map}>
            {showUnits && <MarkersUnits units={units} />}
            {showEvents && <MarkersEvents events={events} />}
            {renderGeofences()}
            {renderSelectedPath()}
            {renderDrawingManager()}
            {renderToolbar()}
            {pointMnu && renderPointMenu()}
          </GoogleMap>
        </div>
        {renderPanel()}
      </div>
    </Content>
  );
}

const mapStateToProps = (state) => {
  return {
    themeMode: state.theme.mode,
    mapOptions: state.map.options,
    events: state.events,
    units: state.units,
    dictionary: state.dictionary,
    zoneDataChanged: state.dataChange.zones,
  };
};

export default connect(mapStateToProps, {
  showSpinner,
  hideSpinner,
  notify,
  updateZones,
  handleError,
  importEsns,
  importZones,
})(GeofenceDesigner);
