import React, { useRef, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { getPartyPerson, getPersonContactInfo } from 'reducers/EventsReducer';
import { personLookup } from 'reducers/PersonReducer';
import { handleError } from 'reducers/ErrorReducer';
import { displayDate } from 'reducers/TimeReducer';
import { IconButton } from '@material-ui/core';
import { AddIcon } from 'evergreen-ui';
import TextFieldPlus from './TextFieldPlus';

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: '0 4px 8px',
  },
  activeWarrant: {
    background: theme.palette.error.main,
    color: theme.card.bg,
    width: 'calc(100% + 32px)',
    display: 'inline-block',
    padding: '8px 16px',
    margin: '-8px -16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 14,
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  },
  option: {
    fontSize: 14,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    '& em': {
      fontStyle: 'normal',
      fontSize: 12,
    },
  }
}));

const btnStyle = {
  margin: '0 8px 0 2px',
  padding: 0,
};

function PersonLookup(props) {
  const { dictionary, error, compact, dialogs, dataUpdate } = props;
  const classes = useStyles();
  const throttleRef = useRef(0);
  const [searchMatch, setSearchMatch] = useState([]);
  const [personData, setPersonData] = useState(null);
  const [suppressSearch, setSuppressSearch] = useState(false);
  const { ptsPersonID, label = 'Search Person', defaultOptions } = props;
  const mountedRef = useRef(true);
  const addEnabled = dialogs.addPerson === null;

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (ptsPersonID !== undefined) getPerson(ptsPersonID);
    // eslint-disable-next-line
  }, [ptsPersonID]);

  useEffect(() => {
    if (!defaultOptions) return;
    setSearchMatch(defaultOptions);
    // eslint-disable-next-line
  }, [defaultOptions]);

  useEffect(() => {
    if (!dataUpdate) return;
    const { type, data } = dataUpdate;
    if (type === 'Person' && data?.action === 'Create') getPerson(data.ptsPersonID);
    // eslint-disable-next-line
  }, [dataUpdate]);

  const getPerson = async (ptsPersonID) => {
    if (!ptsPersonID) return;
    try {
      const personData = await getPartyPerson(ptsPersonID);
      if (!mountedRef.current) return;
      setSuppressSearch(true);
      setSearchMatch([personData]);
      setPersonData(personData);
      await onSearchSelect(null, personData);
      setSuppressSearch(false);
    } catch (err) {
      handleError(err);
    }
  }

  const searchPerson = (text) => {
    if (suppressSearch) return;
    clearTimeout(throttleRef.current);
    throttleRef.current = setTimeout(() => {
      personLookup(text)
        .then((result) => {
          if (!mountedRef.current) return;
          setSearchMatch(result);
        })
        .catch((err) => props.handleError(err, 'Cannot contact API'));
    }, 500);
  };

  const onPersonChange = (data) => {
    if (props.onPersonChange) return props.onPersonChange(data); // ToDo: should be refactored
    if (props.onChange) return props.onChange(data);
  }

  const onSearchSelect = (ev, selection) => {
    if (!selection) {
      setPersonData(null);
      onPersonChange(null);
      return;
    }
    setPersonData(selection);
    getPersonContactInfo(selection.ptsPersonID)
      .then((result) => {
        if (!mountedRef.current) return;
        onPersonChange({ ...selection, ContactInfo: result });
      })
      .catch((err) => props.handleError(err, 'Cannot contact API'));
  };

  const getOptionSelected = (option, value) => {
    return option.ptsPersonID === value.ptsPersonID;
  };

  const renderOption = (option) => {
    let label = option.FullName;
    if (option.Race) {
      const desc = dictionary.Race.find(s => s.Code === option.Race);
      if (desc) label += ' Race: ' + desc.Description;
    }
    if (option.Sex) {
      const desc = dictionary.Sex.find(s => s.Code === option.Sex);
      if (desc) label += ' Sex: ' + desc.Description;
    }
    if (option.Born) {
      label += ' DOB: ' + displayDate(option.Born);
    }
    if (option.SSN) label += ' SSN: ' + option.SSN;
    if (option.OLN) label += ' OLN: ' + option.OLN;


    return (
      <span className={option.ActiveWarrant ? classes.activeWarrant : classes.option}>
        {label}
      </span>
    );
  }

  const helperText = compact ? undefined : typeof error === 'string' ? error : ' '

  return (
    <Autocomplete
      disabled={props.disabled}
      className={props.className}
      fullWidth
      autoHighlight
      options={searchMatch}
      value={personData}
      getOptionLabel={(option) => option.FullName}
      size="small"
      onChange={onSearchSelect}
      onInputChange={(ev, val) => searchPerson(val)}
      renderInput={(params) => {
        params.label = label;
        params.variant = 'outlined';
        return <TextFieldPlus
          {...params}
          InputProps={{
            ...params.InputProps,
            autoComplete: 'new-password',
          }}
        />
      }}
      getOptionSelected={getOptionSelected}
      renderOption={renderOption}
    />
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
    dialogs: state.dialogs,
    dataUpdate: state.dataUpdate,
  }
}

export default connect(mapStateToProps, {
  handleError,
})(PersonLookup);
