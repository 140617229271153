const attachmentsSubMenu = [
  {
    type: 'link',
    label: 'Attachment Types',
    link: 'attachment-types',
    service: 'codeattachmenttypes',
  },
  /* { type: 'link', label: 'Image View Types', service: '' }, */
];

const CADSubMenu = [
  { type: 'link', label: 'Alert Types', link: 'alert-types', service: 'codealerttypes' },
  // { type: 'link', label: 'Call Methods', service: '' },
  {
    type: 'link',
    label: 'Capability Types',
    link: 'capability-types',
    service: 'codecapabilitytypes',
  },
  { type: 'link', label: 'Designations', link: 'designations', service: 'codedesignation' },
  { type: 'link', label: 'Dispositions', link: 'dispositions', service: 'code-dispositions' },
  {
    type: 'link',
    label: 'Party Relationship',
    link: 'party-relationships',
    service: 'codepartyrelationship',
  },
  {
    type: 'link',
    label: 'Resource Categories',
    link: 'resource-categories',
    service: 'coderesourcecategories',
  },
  {
    type: 'link',
    label: 'Unit Action Modifier',
    link: 'unit-action-modifier',
    service: 'codeunitactionmodifier',
  },
  { type: 'link', label: 'Unit Actions', link: 'unit-actions', service: 'codeunitactions' },
  /* { type: 'link', label: 'ESNs', service: '' }, */
  /* { type: 'link', label: 'Event Types', service: '' }, */
  /* { type: 'link', label: 'Event Sub Types', service: '' }, */
];

const contactSubMenu = [
  {
    type: 'link',
    label: 'Contact Info Method Types',
    link: 'contact-info-method-types',
    service: 'codecontactinfomethodtypes',
  },
];

const featureSubMenu = [
  { type: 'link', label: 'Appearances', link: 'appearances', service: 'codeappearance' },
  { type: 'link', label: 'Blood Types', link: 'blood-types', service: 'codebloodtype' },
  { type: 'link', label: 'Builds', link: 'builds', service: 'codebuilds' },
  { type: 'link', label: 'Complexions', link: 'complexions', service: 'codecomplexions' },
  { type: 'link', label: 'Ethnicity', link: 'ethnicity', service: 'codeethnicity' },
  { type: 'link', label: 'Eye Colors', link: 'eye-colors', service: 'codeeyecolor' },
  { type: 'link', label: 'Eye Defects', link: 'eye-defects', service: 'codeeyedefect' },
  { type: 'link', label: 'Facial Hair', link: 'facial-hair', service: 'codefacialhair' },
  { type: 'link', label: 'Glasses', link: 'glasses', service: 'codeglasses' },
  { type: 'link', label: 'Hair Colors', link: 'hair-colors', service: 'codehaircolor' },
  { type: 'link', label: 'Hair Lengths', link: 'hair-lengths', service: 'codehairlength' },
  { type: 'link', label: 'Hair Styles', link: 'hair-styles', service: 'codehairstyle' },
  { type: 'link', label: 'Handed', link: 'handed', service: 'codehanded' },
  { type: 'link', label: 'Mark Locations', link: 'mark-locations', service: 'codemarklocation' },
  { type: 'link', label: 'Marks', link: 'marks', service: 'codemarks' },
  {
    type: 'link',
    label: 'Methods of Operation',
    link: 'methods-of-operation',
    service: 'codemethodofoperation',
  },
  {
    type: 'link',
    label: 'Speech Qualities',
    link: 'speech-qualities',
    service: 'codespeechquality',
  },
  { type: 'link', label: 'Talks', link: 'talks', service: 'codetalk' },
  { type: 'link', label: 'Teeth', link: 'teeth', service: 'codeteeth' },
];

const credentialsSubMenu = [
  { type: 'link', label: 'Classes', link: 'classes', service: 'codecredentialclasses' },
  { type: 'link', label: 'Types', link: 'types', service: 'codecredentialtypes' },
];
const measureSubMenu = [
  { type: 'link', label: 'Units of Measure', link: 'units-of-measure', service: 'codemeasures' },
];

const itemsSubMenu = [
  { type: 'link', label: 'Categories', link: 'categories', service: 'codeitemcategory' },
  { type: 'link', label: 'Conditions', link: 'conditions', service: 'codeitemconditions' },
  {
    type: 'link',
    label: 'DEA Drug Classes',
    link: 'dea-drug-classes',
    service: 'codedeadrugclasses',
  },
  {
    type: 'link',
    label: 'Dispositions',
    link: 'item-dispositions',
    service: 'codeitemdisposition',
  },
  {
    type: 'link',
    label: 'Firearm Calibers',
    link: 'firearm-calibers',
    service: 'codefirearmcaliber',
  },
  {
    type: 'link',
    label: 'Firearm Categories',
    link: 'firearm-categories',
    service: 'codefirearmcategory',
  },
  {
    type: 'link',
    label: 'Identification Categories',
    link: 'identification-categories',
    service: 'codeitemidentificationcategory',
  },
  {
    type: 'link',
    label: 'Jewelry Categories',
    link: 'jewelry-categories',
    service: 'codejewelrycategory',
  },
  { type: 'link', label: 'Makes', link: 'makes', service: 'codeitemmakes' },
  { type: 'link', label: 'Materials', link: 'materials', service: 'codematerial' },
  { type: 'link', label: 'Models', link: 'models', service: 'codeitemmodels' },
  { type: 'link', label: 'Statuses', link: 'statuses', service: 'codeitemstatus' },
  { type: 'link', label: 'Stone Types', link: 'stone-types', service: 'codestonetype' },
  {
    type: 'link',
    label: 'Substance Categories',
    link: 'substance-categories',
    service: 'codesubstancecategory',
  },
  { type: 'link', label: 'Usages', link: 'usages', service: 'codeitemusage' },
];
const placeSubMenu = [
  { type: 'link', label: 'Types', link: 'place-types', service: 'codeplacetypes' },
];
const rotationSubMenu = [
  { type: 'link', label: 'Rotation', link: 'rotation', service: 'coderotation' },
];
const zonesSubMenu = [
  { type: 'link', label: 'Types', link: 'zone-types', service: 'codezonetypes' },
];

const vehicleSubMenu = [
  { type: 'link', label: 'Branders', link: 'vehicle-branders', service: 'codevehiclebranders' },
  { type: 'link', label: 'Brands', link: 'vehicle-brands', service: 'codevehiclebrands' },
  {
    type: 'link',
    label: 'Classifications',
    link: 'vehicle-classifications',
    service: 'codevehicleclassifications',
  },
  { type: 'link', label: 'Colors', link: 'vehicle-colors', service: 'codevehiclecolors' },
  {
    type: 'link',
    label: 'Commercials',
    link: 'vehicle-commercials',
    service: 'codevehiclecommercial',
  },

  {
    type: 'link',
    label: 'Garage Facilities',
    link: 'garage-facilities',
    service: 'codegaragefacilities',
  },
  { type: 'link', label: 'Makes', link: 'vehicle-makes', service: 'codevehiclemakes' },
  { type: 'link', label: 'Models', link: 'vehicle-models', service: 'codevehiclemodels' },
  { type: 'link', label: 'Plate Colors', link: 'plate-colors', service: 'codeplatecolors' },
  { type: 'link', label: 'Plate Details', link: 'plate-details', service: 'codeplatedetails' },
  { type: 'link', label: 'Plate Statuses', link: 'plate-statuses', service: 'codeplatestatuses' },
  { type: 'link', label: 'Plate Types', link: 'plate-types', service: 'codeplatetypes' },
  { type: 'link', label: 'Properties', link: 'vehicle-properties', service: 'codevehicleproperty' },
  { type: 'link', label: 'Styles', link: 'vehicle-styles', service: 'codevehiclestyles' },
  { type: 'link', label: 'Transmissions', link: 'transmissions', service: 'codetransmissions' },
];

const employmentSubMenu = [
  { type: 'link', label: 'Occupations', link: 'occupations', service: 'codeoccupations' },
  { type: 'link', label: 'Positions', link: 'positions', service: 'codepositions' },
  { type: 'link', label: 'Ranks', link: 'ranks', service: 'coderanks' },
  {
    type: 'link',
    label: 'Statuses',
    link: 'employment-statuses',
    service: 'codeemploymentstatuses',
  },
  {
    type: 'link',
    label: 'Termination Reasons',
    link: 'termination-reasons',
    service: 'codeterminationreasons',
  },
];

const PersonSubmenu = [
  {
    type: 'link',
    label: 'Associate Relationships',
    link: 'associate-relationships',
    service: 'codeassociaterelationships',
  },
  { type: 'link', label: 'Citizenships', link: 'citizenships', service: 'codecitizenships' },
  { type: 'link', label: 'Dangerous Types', link: 'dangerous-types', service: 'codedangeroustype' },
  { type: 'link', label: 'Death Causes', link: 'death-causes', service: 'codedeathcauses' },
  { type: 'link', label: 'Gangs', link: 'gangs', service: 'codegang' },
  { type: 'link', label: 'Languages', link: 'languages', service: 'codelanguages' },
  { type: 'link', label: 'Marital Status', link: 'marital-status', service: 'codemaritalstatus' },
  { type: 'link', label: 'Name Prefixes', link: 'name-prefixes', service: 'codenameprefixes' },
  { type: 'link', label: 'Name Suffixes', link: 'name-suffixes', service: 'codenamesuffixes' },
  { type: 'link', label: 'Name Types', link: 'name-types', service: 'codepersonnametypes' },
  { type: 'link', label: 'Nationalities', link: 'nationalities', service: 'codenationalities' },
  { type: 'link', label: 'Races', link: 'races', service: 'coderace' },
  { type: 'link', label: 'Residents', link: 'resident', service: 'coderesident' },
  { type: 'link', label: 'Sexes', link: 'sexes', service: 'codesex' },
];

const locationSubMenu = [
  {
    type: 'link',
    label: 'Address Building Types',
    link: 'address-building-types',
    service: 'codeaddressbldgtypes',
  },
  {
    type: 'link',
    label: 'Address Categories',
    link: 'address-categories',
    service: 'codeaddresscategories',
  },
  {
    type: 'link',
    label: 'Address Floor Types',
    link: 'address-floor-types',
    service: 'codeaddressfloortypes',
  },
  {
    type: 'link',
    label: 'Address Party Relationships',
    link: 'address-party-relationships',
    service: 'codeaddresspartyrelationship',
  },
  {
    type: 'link',
    label: 'Address Unit Types',
    link: 'address-unit-types',
    service: 'codeaddressunittypes',
  },
  { type: 'link', label: 'Cities', link: 'cities', service: 'codecities' },
  {
    type: 'link',
    label: 'Coordinate Datum Types',
    link: 'coordinate-datum-types',
    service: 'codecoordinatedatumtypes',
  },
  { type: 'link', label: 'Counties', link: 'counties', service: 'codecounties' },
  { type: 'link', label: 'Countries', link: 'countries', service: 'codecountries' },
  { type: 'link', label: 'Garage Types', link: 'garage-types', service: 'codegaragetypes' },
  {
    type: 'link',
    label: 'Pre Plan Construction Types',
    link: 'pre-plan-construction-types',
    service: 'codepreplanconstructiontypes',
  },
  {
    type: 'link',
    label: 'Pre Plan Occupancy Types',
    link: 'pre-plan-occupancy-types',
    service: 'codepreplanoccupancytypes',
  },
  {
    type: 'link',
    label: 'Pre Plan Roof Types',
    link: 'pre-plan-roof-types',
    service: 'codepreplanrooftypes',
  },
  { type: 'link', label: 'States', link: 'states', service: 'codestates' },
  {
    type: 'link',
    label: 'Street Directions',
    link: 'street-directions',
    service: 'codestreetdirections',
  },
  { type: 'link', label: 'Street Types', link: 'street-types', service: 'codestreettypes' },
  {
    type: 'link',
    label: 'Structure Colors',
    link: 'structure-colors',
    service: 'codestructurecolors',
  },
  /* { type: 'link', label: 'Categories', service: '' }, */
];
const menu = [
  { type: 'menu', label: 'Attachment', submenu: attachmentsSubMenu },
  { type: 'menu', label: 'CAD', submenu: CADSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Contact', submenu: contactSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Credentials', submenu: credentialsSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Employment', submenu: employmentSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Feature', submenu: featureSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Items', submenu: itemsSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Location', submenu: locationSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Measure', submenu: measureSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Person', submenu: PersonSubmenu },
  { type: 'divider' },
  { type: 'menu', label: 'Vehicle', submenu: vehicleSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Place', submenu: placeSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Rotation', submenu: rotationSubMenu },
  { type: 'divider' },
  { type: 'menu', label: 'Zones', submenu: zonesSubMenu },
];

export default menu;
