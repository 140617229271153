import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '../../components/Dialog';
import Button from '@material-ui/core/Button';
import { connect } from 'react-redux';
import CustomAutocomplete from '../../components/CustomAutocomplete';
import { closeImportZones } from '../../reducers/DialogsReducer';
import { handleError } from '../../reducers/ErrorReducer';
import { showSpinner, hideSpinner } from '../../reducers/UiReducer';
import { notify, closeSnackbar } from '../../reducers/NotifierReducer';
import CloseIcon from '@material-ui/icons/Close';
import Tooltip from '../../components/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { asyncForEach } from '../../utils/functions';
import { zonesChanged } from '../../reducers/DataChangeReducer';
import { saveZone, getZones, removeZone } from '../../reducers/ZonesReducer';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import GetAppIcon from '@material-ui/icons/GetApp';
import PublishIcon from '@material-ui/icons/Publish';
import ImportExportIcon from '@material-ui/icons/ImportExport';

function getFormStyle(minWidth, maxWidth) {
  return {
    maxWidth: maxWidth,
    flexBasis: minWidth,
    minWidth: minWidth,
    flexGrow: 1,
    margin: `0 4px 12px`,
  };
}

const useStyles = makeStyles((theme) => ({
  wrap: {
    width: 600,
    maxWidth: '100%',
    minHeight: 400,
    marginTop: -24,
  },
  content: {
    paddingTop: 16
  },
  row: {
    display: 'flex',
  },
  form: {
    margin: `0 -${theme.spacing(0.5)}px`,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
    marginBottom: 16,
  },

  importCard: {
    padding: theme.spacing(1.5),
    '& $form': {
      marginTop: theme.spacing(2),
    },
  },
  warning: {
    marginBottom: theme.spacing(1),
  },
  error: {
    marginBottom: theme.spacing(1),
  },
  columns: {
    display: 'flex',
    justifyContent: 'space-between',
    '& > div': {
      width: `calc(50% - ${theme.spacing(1)}px)`,
    },
  },
  backupAction: {
    textAlign: 'right',
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },

  edit: {
    padding: theme.spacing(2),
    paddingBottom: 12,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'center',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: theme.spacing(1),
    '& h4': {
      margin: '0.5em 0',
    },
  },
  textField: {
    ...getFormStyle(200, 200),
  },
  importBtn: {
    margin: `0 ${theme.spacing(0.5)}px`,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  btn: {
    margin: `-${theme.spacing(1)}px ${theme.spacing(0.5)}px 0`,
    '& svg': {
      marginRight: theme.spacing(1),
    },
  },
  w100pr: {
    width: '100%',
    margin: `0 4px 8px`,
  },
  requiredInfo: {
    fontSize: '0.75rem',
    marginBottom: 0,
  },
  checkbox: {
    margin: `-${theme.spacing(0.5)}px ${theme.spacing(0.5)}px 0`,
  },
  actions: {
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  actions2: {
    '& button': {
      marginLeft: theme.spacing(1),
      opacity: 0,
      transition: 'opacity 0.3s',
    },
  },
  rrr: {
    '&:hover': {
      '& button': {
        opacity: 1,
      },
    },
  },
  input: {
    display: 'none',
  },
}));

function ImportZones(props) {
  const classes = useStyles();
  const { dictionary } = props;
  const [tab, setTab] = useState('export'); // export, import, delete
  const [fileLoaded, setFileLoaded] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [loadedData, setLoadedData] = useState(null);
  const [agencies, setAgencies] = useState([]);
  const [zones, setZones] = useState([]);
  const [agency, setAgency] = useState(null);
  const [zonesNo, setZonesNo] = useState(0);
  const [availAgencies, setAvailAgencies] = useState([]);
  const [targetAgency, setTargetAgency] = useState(null);
  const [sourceAgency, setSourceAgency] = useState(null);
  const [loadedAgencies, setLoadedAgencies] = useState([]);
  const backupFileRef = useRef();
  const validFile = selectedFile?.type === 'application/json';
  const fileName = selectedFile?.name || null;

  useEffect(() => {
    loadZones();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!dictionary) return;
    const agencies = dictionary.Agencies.map((d) => ({ ...d, Code: d.AgencyID }));
    setAvailAgencies(agencies);
    // eslint-disable-next-line
  }, [dictionary]);

  const loadZones = async () => {
    try {
      const agencies = [{ Code: 'All' }];
      const zones = await getZones();
      zones.forEach((z) => {
        if (agencies.findIndex((el) => el.Code === z.AgencyID) === -1) agencies.push({ Code: z.AgencyID });
      });
      setAgencies(agencies);
      setAgency(agencies[0]);
      setZones(zones);
      setZonesNo(zones.length);
    } catch (err) {
      props.handleError(err);
    }
  }

  const close = () => props.closeImportZones();

  const renderAgencies = () => {
    const onChange = (val) => {
      setAgency(val);
      let agencyZones = [...zones];
      if (val?.Code !== 'All') agencyZones = zones.filter((z) => z.AgencyID === val?.Code);
      setZonesNo(agencyZones.length);
    }

    return (
      <CustomAutocomplete
        className={classes.textField}
        label="Agency*"
        options={agencies}
        value={agencies.length > 0 ? agency : null}
        onChange={onChange}
      />
    );
  }

  const handleChangeBackupFile = ev => {
    const file = ev.target.files[0];
    setSelectedFile(file);
    if (!file || file.type !== 'application/json') return;
    handleBackupLoad(file);
  }

  const handleBackupLoad = (file) => {
    const reader = new FileReader()
    reader.onload = ev => {
      processBackupFile(ev.target.result);
    };
    reader.onerror = (error) => {
      props.notify('Error, file not loaded', 'error');
    }
    reader.readAsText(file)
  }

  const processBackupFile = async (strData) => {
    let errors = false;
    let data;
    try {
      data = JSON.parse(strData);
      data.forEach(row => {
        if (!row.ZoneCode || !row.AgencyID) errors = true;
      });
    } catch (err) {
      errors = true;
    }
    if (errors) {
      if (window.confirm("Some zones contain errors. Continue loading only valid zones?")) {
        data = data.filter((z) => z.ZoneCode && z.AgencyID);
        errors = false;
      }
    }
    if (!errors && data.length > 0) {
      setFileLoaded(true);
      setLoadedData(data);
      const agencies = data
        .map((z) => z.AgencyID)
        .reduce((res, AgencyID) => (res.indexOf(AgencyID) === -1 ? [...res, AgencyID] : res), [])
        .map((AgencyID) => ({ Code: AgencyID }));
      setLoadedAgencies(agencies);
      if (agencies.length === 1) {
        setSourceAgency(agencies[0]);
        const AgencyID = agencies[0].Code;
        const targetAgency = availAgencies.find((a) => a.Code === AgencyID);
        if (!targetAgency) return;
        setTargetAgency(targetAgency);
      }
    } else if (errors) {
      props.notify('Processing file error', 'error');
    } else {
      props.notify('Backup file contains no data.', 'warning');
    }
  }

  const renderActions = () => {
    return (
      <>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  const renderExport = () => {
    const onClick = () => {
      const data = agency.Code === 'All' ? zones : zones.filter((z) => z.AgencyID === agency.Code);
      data.forEach((z) => {
        delete z.ROWGUID;
        delete z.ptsGeofenceID;
      });
      const dataStr = JSON.stringify(data);
      const file = new Blob([dataStr], { type: 'text/json' });
      const dateStr = (new Date().toISOString()).replaceAll(':', '-');
      const name = `Zones-${agency.Code}-${dateStr}.bak.json`;
      const $a = document.createElement('a');
      $a.href = window.URL.createObjectURL(file);
      $a.download = name;
      $a.click();
    }

    return (
      <div>
        <div className={classes.row}>
          {renderAgencies()}
          <div>
            <Button
              color="primary"
              onClick={onClick}
              variant="contained"
              className={classes.importBtn}
              disabled={!zonesNo}
            >
              <GetAppIcon /> Export
            </Button>
          </div>

        </div>
        <p> No of zones: <span>{zonesNo}</span></p>
      </div>
    );
  }

  const importData = async () => {
    const sourceAgencyID = sourceAgency.Code;
    const tartegAgencyID = targetAgency.Code;
    const zones = loadedData
      .filter((z) => z.AgencyID === sourceAgencyID)
      .map((z) => {
        if (!z.path || !z.path.length) return { ...z, AgencyID: tartegAgencyID, Code: 'Zone', SurfaceText: null };
        const SurfaceText = z.path.reduce((res, p, idx) => {
          let out = idx ? res + ', ' : res;
          out += `${p.lng} ${p.lat}`;
          return out;
        }, '')
        return { ...z, AgencyID: tartegAgencyID, Code: 'Zone', SurfaceText };
      })
      .map((z) => {
        if (!z.SurfaceText) return z;
        return { ...z, SurfaceText: z.SurfaceText + `, ${z.path[0].lng} ${z.path[0].lat}` }
      });
    // 1. sprawdzić czy destination is free of zones - TODO
    props.showSpinner();
    try {
      await asyncForEach(zones, async (zone) => {
        await saveZone(zone);
      });
      props.notify('Zones imported', 'success');
      props.zonesChanged();
      loadZones();
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
  }

  const renderImport = () => {
    const disabled = !sourceAgency || !targetAgency || zones.filter((z) => z.AgencyID === targetAgency.Code).length > 0;
    const onSourceChange = (val) => {
      if (val) {
        const AgencyID = val.Code;
        const existingAgency = availAgencies.find((a) => a.AgencyID === AgencyID);
        if (existingAgency) setTargetAgency(existingAgency);
      }
      setSourceAgency(val);
    }
    return (
      <div>
        <div className={classes.form}>
          <Tooltip title="Restore geofences">
            <div className={classes.importBtn}>
              <input
                accept=".json"
                className={classes.input}
                id="import-zone-backup-file"
                multiple
                type="file"
                onChange={handleChangeBackupFile}
                ref={backupFileRef}
              />
              <label htmlFor="import-zone-backup-file">
                <Button
                  color="primary"
                  component="span"
                  variant="contained"
                >
                  <PublishIcon />
                  Choose Backup File
                </Button>
              </label>
            </div>
          </Tooltip>
        </div>
        {Boolean(fileName) && <p>
          {fileName} {!validFile && <span style={{ color: 'red' }}>
            Invalid file type!
          </span>}
        </p>}
        {fileLoaded && <div style={{ marginTop: 16 }} className={classes.row}>
          <CustomAutocomplete
            className={classes.textField}
            label="Source Agency"
            options={loadedAgencies}
            value={sourceAgency}
            onChange={onSourceChange}
          />
          <CustomAutocomplete
            className={classes.textField}
            label="Destination Agency"
            options={availAgencies}
            value={targetAgency}
            onChange={setTargetAgency}
          />
          <div>
            <Button
              color="primary"
              disabled={disabled}
              variant="contained"
              onClick={importData}
            >
              <ImportExportIcon />
              Import
            </Button>
          </div>
        </div>}
      </div>
    );
  }

  const deleteZones = async (data) => {
    props.showSpinner();
    try {
      await asyncForEach(data, async (zone) => {
        await removeZone(zone);
      });
    } catch (err) {
      props.handleError(err);
    }
    props.hideSpinner();
    props.zonesChanged();
    loadZones();
  }

  const renderDelete = () => {
    const onClick = () => {
      if (!window.confirm('Are you sure you want to delete all selected zones?')) return;
      const data = agency.Code === 'All' ? zones : zones.filter((z) => z.AgencyID === agency.Code);
      deleteZones(data);
    }
    return (
      <div>
        <div className={classes.row}>
          {renderAgencies()}
          <div>
            <Button
              color="primary"
              onClick={onClick}
              variant="contained"
              className={classes.importBtn}
              disabled={!zonesNo}
            >
              <DeleteIcon /> Delete
            </Button>
          </div>
        </div>
        <p> No of zones: <span>{zonesNo}</span></p>
      </div>
    );
  }

  return (
    <Dialog open={true} onClose={close} title="Import / Export Geofences" actions={renderActions()} toolbar>
      <div className={classes.wrap}>
        <Tabs value={tab} onChange={(ev, val) => setTab(val)}>
          <Tab label="Export Zones" value="export" />
          <Tab label="Import Zones" value="import" />
          <Tab label="Delete Zones" value="delete" />
        </Tabs>
        <div className={classes.content}>
          {tab === 'export' && renderExport()}
          {tab === 'import' && renderImport()}
          {tab === 'delete' && renderDelete()}
        </div>

        {/* {fileLoaded && fileType === 'backup' && renderLoadedBackup()} */}
        {/* {renderInputOptions()} */}
      </div>
    </Dialog >
  );
}

const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary,
  };
};

export default connect(mapStateToProps, {
  closeImportZones,
  notify,
  showSpinner,
  hideSpinner,
  handleError,
  closeSnackbar,
  zonesChanged,
})(ImportZones);
