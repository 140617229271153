import React, { useState, useRef, useEffect } from 'react';
import { connect, useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from 'components/Tooltip';
import Fab from '@material-ui/core/Fab';
import DeleteIcon from '@material-ui/icons/Delete';
import CheckIcon from '@material-ui/icons/Check';
import EditIcon from '@material-ui/icons/Edit';
import AddIcon from '@material-ui/icons/Add';
import XGrid3 from 'components/XGrid3';
import QuickFilterSearch from 'components/QuickFilterSearch';
import { getService } from 'reducers/service';
import { displayDateTime } from 'reducers/TimeReducer';
import { showSpinner, hideSpinner } from 'reducers/UiReducer';
import { handleError } from 'reducers/ErrorReducer';
import { showContactMethod } from 'reducers/DialogsReducer';
import { getAgency, getPtsPlace } from 'Settings/Agencies/AgencyFunctions';

const useStyles = makeStyles((theme) => ({
  wrap: {
    padding: theme.spacing(3),
  },
  gridWrap: {
    width: '100%',
    boxSizing: 'border-box',
    height: 600,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: theme.spacing(2),
    '& button': {
      marginLeft: theme.spacing(1),
    },
  },
  fileInput: {
    display: 'none',
  },
  filterWrap: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    marginBottom: 8,
  },
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const defaultColumns = [
  { field: 'ContactMethod', headerName: 'Contact Method', width: 150},
  {
    field: 'MethodType',
    headerName: 'Type',
    width: 120,
  },
  { field: 'Info', headerName: 'Info', width: 150},
  {
    field: 'IsDefault',
    headerName: 'IsDefault',
    width: 100,
    format: 'bool',
    renderCell: bool,
  },
  {
    field: 'Created',
    headerName: 'Created',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  {
    field: 'Updated',
    headerName: 'Updated',
    width: 120,
    valueFormatter: (params) => displayDateTime(params.value),
    format: 'date',
  },
  { field: 'UpdatedBy', headerName: 'Updated By', width: 150 },
];

const PlaceContact = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { agencyId, dataUpdate } = props;
  const [rows, setRows] = useState([]);
  const [ptsPlaceID, setPtsPlaceID] = useState(null);
  const [filteredRows, setFilteredRows] = useState([]);
  const [selection, setSelection] = useState(null);
  const [columns, setColumns] = useState(defaultColumns);


  useEffect(() => {
    search();
  }, []);

  useEffect(() => {
    if (dataUpdate && dataUpdate.type === "agencies") search();
  }, [dataUpdate])

  const search = async () => {
    const curAgency = await getAgency(agencyId);
    const { ptsPlaceID } = curAgency;
    setPtsPlaceID(ptsPlaceID);
    const curPlace = await getPtsPlace(ptsPlaceID);
    console.log(curPlace.ContactInfo)
    setRows(addIdToContact(curPlace.ContactInfo));
  }

  const addIdToContact = (infos) => {
    return infos.map(info => {
      info.id = info.ptsInfoID;
      return info;
    });
  }
  
  const add = () => {
    dispatch(showContactMethod({ ptsParentID: ptsPlaceID, PartyType: 'Place' }));
  };

  const edit = () => {
    const item = rows.find((row) => row.id == selection);
    dispatch(showContactMethod({ ...item }));
  };

  const deleteContact = async () => {
    const item = rows.find((row) => row.id == selection);
    try {
      dispatch(showSpinner());
      const service = getService('record-person-contact');
      await service.remove(item.ptsInfoID);
      search();
    } catch (err) {
      dispatch(handleError(err));
    } finally {
      dispatch(hideSpinner());
    }
  };

  return (
    <div className={classes.wrap}>
      <div className={classes.filterWrap}>
        <div className={classes.filterWrap}>
          <QuickFilterSearch rows={rows} columns={columns} setFilteredRows={setFilteredRows} />
        </div>
        <div className={classes.actions}>
          <Tooltip title="Add">
            <Fab size="small" color="secondary" onClick={add}>
              <AddIcon />
            </Fab>
          </Tooltip>

          {selection !== null && (
            <>
              <Tooltip title="Edit">
                <Fab size="small" color="secondary" onClick={edit}>
                  <EditIcon />
                </Fab>
              </Tooltip>

              <Tooltip title="Delete">
                <Fab size="small" color="secondary" onClick={deleteContact}>
                  <DeleteIcon />
                </Fab>
              </Tooltip>
            </>
          )}
        </div>
      </div>
      <XGrid3 
        columns={columns}
        setColumns={setColumns}
        rows={filteredRows}
        setSelection={setSelection}
      />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    editContactMethod: state.dialogs.editContactMethod,
    dataUpdate: state.dataUpdate
  };
};

export default connect(mapStateToProps, { handleError, showSpinner, hideSpinner })(PlaceContact);
