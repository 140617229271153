import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Content from '../../Content';
import { findSearchProfiles, findSearchClearanceLevels, findSearchUsers, updateSearchUser } from '../SecFunctions';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import { XGrid } from '@material-ui/x-grid';
import CheckIcon from '@material-ui/icons/Check';
import { formatUTCdate } from 'utils/functions';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import { handleError } from 'reducers/ErrorReducer';
import { notify } from 'reducers/NotifierReducer';
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';
import AddIcon from '@material-ui/icons/Add';
import { showAddSecUser } from 'reducers/DialogsReducer';
import Fab from '@material-ui/core/Fab';
import Tooltip from 'components/Tooltip';

const useStyles = makeStyles((theme) => ({
  autoComplete: {
    maxWidth: 300,
    marginTop: '15px'
  },
  list: {
    width: '100%',
    height: 500,
  },
  saveBtn: {
    textAlign: 'right',
    marginBottom: 15
  }
}));

const bool = (params) => (params.value ? <CheckIcon /> : <span></span>);

const columns = [
  { field: 'Username',            headerName: 'Username',         width: 150},
  { field: 'FullName',            headerName: 'Fullname',         width: 200 },
  { field: 'Profile',             headerName: 'Profile',          width: 120 },
  { field: 'IsDisabled',          
    headerName: 'Disabled',         
    width: 120,
    format: "bool",
    renderCell: bool 
  },
  { field: 'IsLockedOut',         
    headerName: 'Locked Out',       
    width: 120,
    format: "bool",
    renderCell: bool 
  },
  { field: 'ActivityDateTime',    
    headerName: 'Activity Date Time',    
    width: 150,
    valueFormatter: (params) => formatUTCdate(params.value),
    format: "date",
  },
  { field: 'Activity',            headerName: 'Activity',         width: 120 },
  { field: 'PersonID',            headerName: 'PersonID',         width: 120 },
  { field: 'ClearanceLevel',      headerName: 'Clearance Level',  width: 150 },
];

function SearchUsers(props) {
  const classes = useStyles();
  const [allProfiles, setAllProfiles] = useState([]);
  const [allClearanceLevels, setAllClearanceLevels] = useState([]);
  const [profile, setProfile] = useState(null);
  const [clearance, setClearance] = useState(null);
  const [usersData, setUsersData] = useState([]);
  const [selection, setSelection] = useState(null);
  const [locked, setLocked] = useState(false);


  useEffect(() => {
    fetchUsers();
    fetchProfiles();
    fetchClearanceLevels();
  }, []);


  const fetchUsers = async () => {
    const result = await findSearchUsers();
    setUsersData(processData(result));
  }


  const processData = (data) => {
    return data.map((row) => {
      return {
        ...row,
        id: row.ptsUserID,
      };
    });
  };


  const fetchProfiles = async () => {
    const result = await findSearchProfiles();
    setAllProfiles(result);
  }


  const fetchClearanceLevels = async () => {
    const result = await findSearchClearanceLevels();
    setAllClearanceLevels(result);
  }


  const handleSaveClick = async () => {
    const data = {
      ProfileId: profile.ptsProfileID,
      ClearanceId: clearance ? clearance.ptsClearanceLevelID : null,
      IsLockedOut: locked ? 1 : 0,
      UserId: selection
    };

    try {
      await updateSearchUser(selection, data);
      fetchUsers();
      props.notify("User Updated", "success");
    } catch(err) {
      props.handleError(err, 'Error Updating User');
    }
  }


  const handleSelectChange = (newSelection) => {
    const currentUserId = parseInt(newSelection.selectionModel[0], 10);
    const currentUser = usersData.find(data => data.ptsUserID === currentUserId);
    const { ptsProfileID, ptsClearanceLevelID } = currentUser;
    const currentProfile = allProfiles.find(profile => profile.ptsProfileID === ptsProfileID);

    setSelection(currentUserId);
    setProfile(currentProfile);
    setLocked(currentUser.IsLockedOut);

    if (ptsClearanceLevelID) {
      const currentClearance = allClearanceLevels.find(c => c.ptsClearanceLevelID === ptsClearanceLevelID);
      setClearance(currentClearance);
    }
    else setClearance(null);
  }


  const renderLocked = () => {
    const handleChange = () => setLocked(!locked);
    return (
      <FormControlLabel 
        control={
          <Switch checked={locked} onChange={handleChange}/>
        } 
        label="Is Locked Out" 
        className="mt-2"
      />
    )
  }


  const renderSave = () => {
    return (
      <div className={classes.saveBtn}>
        <Button 
          onClick={handleSaveClick} 
          disabled={!profile || !selection}
          variant="contained"
          color="primary">
          <SaveIcon className="mr-2" /> Save 
        </Button>
      </div>
    )
  }

  const renderProfiles = () => {
    const handleChange = (e, newValue) => setProfile(newValue);
    return (
      <Autocomplete
        disablePortal
        options={allProfiles}
        className={classes.autoComplete}
        renderInput={(params) => <TextField {...params} label="Profiles" variant="outlined" />}
        onChange={handleChange}
        getOptionLabel={(option) => option.Label}
        renderOption={option => `${option.Label} ${option.Type ? - option.Type : ''}`}
        size="small"
        value={profile}
        disabled={!selection}
      />
    )
  }

  const renderClearance = () => {
    const handleChange = (e, newValue) => setClearance(newValue);
    return (
      <Autocomplete
        disablePortal
        options={allClearanceLevels}
        className={classes.autoComplete}
        renderInput={(params) => <TextField {...params} label="Clearance Levels" variant="outlined" />}
        onChange={handleChange}
        getOptionLabel={(option) => option.Level}
        size="small"
        value={clearance}
        disabled={!selection}
      />
    )
  }

  const renderUsers = () => {
    return (
      <div className={classes.list}>
        <XGrid
          columns={columns}
          rows={usersData}
          rowHeight={38}
          onSelectionModelChange={handleSelectChange}
          disableMultipleSelection
          hideFooter
        />
      </div>
    );
  }

  const renderActions = () => {
    return (
      <div>
        <Tooltip title="Add" onClick={() => props.showAddSecUser()} >
          <Fab size="small" color="secondary">
            <AddIcon />
          </Fab>
        </Tooltip>
      </div>
    )
  }
  
  return (
    <Content>
      <div className="m-4">
        <h4 className="mb-4">Search Users</h4>
        {renderProfiles()}
        {renderClearance()}
        {renderLocked()}
        {renderActions()}
        {renderSave()}
        {renderUsers()}
      </div>
    </Content>
  );
}

export default connect(null, { handleError, notify, showAddSecUser })(SearchUsers);

