import React, { useEffect, useState } from 'react';
import SettingsPage from './../Content';
import {
  Grid,
  Paper,
  Typography,
  Divider,
  Box,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import { getService } from './../../reducers/service';
import { getUnitsAsync } from 'reducers/UnitsReducer';
export default function Appearance() {
  const cdr = getService('cdr');
  const [is24Hours, setIs24Hours] = useState(false);

  const handleChangingTime = async event => {
    //TODO:need to implement
    const CDRKey = '24hour';
    const CreatedBy = 'pts';
    const Path = 'Application.TimeFormat';
    try {
      const updateTimeSetting = await cdr.patch(Path, {
        ValueBoolean: event.target.checked,
      });
      setIs24Hours(updateTimeSetting?.ValueBoolean);
    } catch (error) {
      console.log(error);
    }
  };
  const getTimeSetting = async () => {
    try {
      const timeSetting = await cdr.find({ query: { Path: 'Application.TimeFormat' } });
      if (timeSetting.length) setIs24Hours(timeSetting[0].ValueBoolean);
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getTimeSetting();
  }, []);
  return (
      <>
        <Grid container style={{ margin: 10 }}>
          <Paper style={{ width: 150, height: 150 }}>
            <Grid item style={{justifyContent: 'center'}}>
              <Typography
                variant="subtitle2"
                style={{ width: '100%', justifyContent: 'center', display: 'flex' }}>
                Time Setting
              </Typography>
            </Grid>

            <Divider />
            <Grid
              item
              style={{
                display: 'flex',
                alignItems: 'center',
                height: '100%',
              }}>
              <Box style={{ marginLeft: 10 }}>
                <FormControlLabel
                  control={<Checkbox checked={is24Hours} onChange={handleChangingTime} />}
                  label="24Hour"
                />
              </Box>
            </Grid>
          </Paper>
        </Grid>
      </>
  );
}
