export const commonColumns = [
  { field: 'Code', headerName: 'Code', width: 150 },
  { field: 'Description', headerName: 'Description', width: 250 },
  { field: 'Category', headerName: 'Category', width: 150 },
  { field: 'IsDefault', headerName: 'IsDefault', width: 150 },
  { field: 'IsDeleted', headerName: 'IsDeleted', width: 150 },
  { field: 'IsActive', headerName: 'IsActive', width: 150 },
  { field: 'Flags', headerName: 'Flags', width: 100 },
  { field: 'Code2', headerName: 'Code 2', width: 150 },
  { field: 'Description2', headerName: 'Description 2', width: 150 },
  { field: 'Code3', headerName: 'Code 3', width: 100 },
  { field: 'Description3', headerName: 'Description 3', width: 150 },
  { field: 'Code4', headerName: 'Code 4', width: 100 },
  { field: 'Description4', headerName: 'Description 4', width: 150 },
  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150 },
  { field: 'Updated', headerName: 'Updated', width: 150 },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
];

export const partyRelationshipColumns = [
  { field: 'Code', headerName: 'Code', width: 150 },
  { field: 'Description', headerName: 'Description', width: 250 },
  { field: 'Category', headerName: 'Category', width: 150 },
  { field: 'isDefault', headerName: 'IsDefault', width: 150 },
  { field: 'isDeleted', headerName: 'IsDeleted', width: 150 },
  { field: 'isActive', headerName: 'IsActive', width: 150 },
  { field: 'Flags', headerName: 'Flags', width: 100 },
  { field: 'Code2', headerName: 'Code 2', width: 150 },
  { field: 'Description2', headerName: 'Description 2', width: 150 },
  { field: 'Code3', headerName: 'Code 3', width: 100 },
  { field: 'Description3', headerName: 'Description 3', width: 150 },
  { field: 'Code4', headerName: 'Code 4', width: 100 },
  { field: 'Description4', headerName: 'Description 4', width: 150 },
  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150 },
  { field: 'Updated', headerName: 'Updated', width: 150 },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
  { field: 'Entities', headerName: 'Entities', width: 150 },
  { field: 'EmployeeRelated', headerName: 'EmployeeRelated', width: 150 },
  { field: 'Order', headerName: 'Order', width: 150 },
];

export const codeCitiesColumns = [
  { field: 'CityDescription', headerName: 'City Description', width: 250 },
  { field: 'Category', headerName: 'Category', width: 150 },
  { field: 'IsDefault', headerName: 'IsDefault', width: 150 },
  { field: 'IsDeleted', headerName: 'IsDeleted', width: 150 },
  { field: 'IsActive', headerName: 'IsActive', width: 150 },
  { field: 'Flags', headerName: 'Flags', width: 100 },
  { field: 'Description2', headerName: 'Description 2', width: 150 },
  { field: 'Description3', headerName: 'Description 3', width: 150 },
  { field: 'UpdatedBy', headerName: 'UpdatedBy', width: 150 },
  { field: 'Updated', headerName: 'Updated', width: 150 },
  { field: 'DeletedBy', headerName: 'Deleted By', width: 150 },
  { field: 'CreatedBy', headerName: 'Created By', width: 150 },
];

export const codeDispositionColumns = [
  { field: 'AgencyID', headerName: 'AgencyID', width: 250 },
  ...commonColumns,
];

export const codeZonesColumns = [
  { field: 'AgencyID', headerName: 'AgencyID', width: 250 },
  { field: 'Zone1Type', headerName: 'Zone1Type', width: 250 },
  { field: 'Zone2Type', headerName: 'Zone2Type', width: 250 },
  { field: 'Zone3Type', headerName: 'Zone3Type', width: 250 },
  { field: 'Zone4Type', headerName: 'Zone4Type', width: 250 },
  { field: 'Zone5Type', headerName: 'Zone5Type', width: 250 },
  ...commonColumns.slice(2),
];
