const customIdentifiersSubmenu = [
  {
    type: 'menu',
    label: 'CAD',
    submenu: [{ type: 'link', label: 'Events', link: 'custom-statuses' }],
  },
  {
    type: 'menu',
    label: 'Global',
    submenu: [
      { type: 'link', label: 'Addresses', link: 'custom-addresses' },
      { type: 'link', label: 'Alerts', link: 'custom-alerts' },
      { type: 'link', label: 'Cases (Agency Spec.)', link: 'custom-cases' },
      { type: 'link', label: 'Items', link: 'custom-items' },
      { type: 'link', label: 'People', link: 'custom-people' },
      { type: 'link', label: 'Places', link: 'custom-places' },
      { type: 'link', label: 'Vehicles', link: 'custom-vehicles' },
      { type: 'link', label: 'Warrant', link: 'custom-warrant' },
    ],
  },
  {
    type: 'menu',
    label: 'RMS',
    submenu: [
      { type: 'link', label: 'Citation (Agency Spec.)', link: 'custom-citation' },
      { type: 'link', label: 'Firearm', link: 'custom-firearm' },
      { type: 'link', label: 'Jewelry', link: 'custom-jewelry' },
      { type: 'link', label: 'Structure', link: 'custom-structure' },
    ],
  },
];

const duplicateCheckingSubmenu = [
  { type: 'link', label: 'Events', link: 'events-duplicate-checking' },
  { type: 'link', label: 'Vehicles', link: 'vehicles-duplicate-checking' },
  { type: 'link', label: 'People', link: 'people-duplicate-checking' },
  { type: 'link', label: 'Addresses', link: 'addresses-duplicate-checking' },
  { type: 'link', label: 'Places', link: 'places-duplicate-checking' },
];

const securitySubmenu = [
  { type: 'link', label: 'Roles / Permissions', link: 'security-permissions' },
  { type: 'link', label: 'Clearance Levels', link: 'search-clearance-levels' },
  { type: 'link', label: 'Profiles', link: 'search-profiles' },
  { type: 'link', label: 'Users', link: 'search-users' },
  { type: 'link', label: 'Users History', link: 'search-users-history' },
];

const mobileSubmenu = [
  {
    type: 'menu',
    label: 'Mobile',
    submenu: [
      { type: 'link', label: 'General', link: 'mobile' },
      { type: 'link', label: 'Citation', link: 'citation-info' },
      { type: 'link', label: 'Unit Selection', link: 'agency-unit-selection' },
    ],
  },
  { type: 'link', label: 'NCIC', link: 'mobile-ncic' },
];

const menu = [
  { type: 'link', label: 'Appearance', link: 'appearance' },
  { type: 'link', label: 'Resources', link: 'resources' },
  { type: 'menu', label: 'Security', submenu: securitySubmenu },
  { type: 'link', label: 'Rip & Run', link: 'ripandrun' },
  { type: 'divider' },
  { type: 'menu', label: 'Custom Identifiers', submenu: customIdentifiersSubmenu },
  { type: 'link', label: 'Defaults', link: 'defaults' },
  { type: 'menu', label: 'Mobile', submenu: mobileSubmenu },
  { type: 'link', label: 'Two Factor Auth', link: 'two-factor-auth' },
  { type: 'divider' },
  { type: 'link', label: 'Agencies', link: 'agencies' },
  { type: 'link', label: 'Lookup Codes', link: 'lookup-codes' },
  { type: 'menu', label: 'Duplicate Checking', submenu: duplicateCheckingSubmenu },
  { type: 'link', label: 'Event Type Initials & Icons', link: 'event-type-initials' },
  { type: 'link', label: 'Agency Type Icons', link: 'agency-type-icons' },
  { type: 'divider' },
  { type: 'link', label: 'Security Users', link: 'sec-users' },
  { type: 'link', label: 'Add Vehicle Default Place', link: 'vehicle-default-place' },
  { type: 'divider' },
  { type: 'link', label: 'Notifications', link: 'notifications' },
  { type: 'link', label: 'Geofence Designer', link: 'geofence-designer' },
  { type: 'link', label: 'SOPs', link: 'sops' },
  { type: 'link', label: 'Workflows', link: 'workflows' },
];

export default menu;
