import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import SaveIcon from '@material-ui/icons/Save';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import formStyles, { gridStyle, Row, RowInner, ColCard, Fills, formatSaveData } from 'utils/formStyles';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import PlaceLookup from 'components/PlaceLookup';
import Autocomplete2 from 'components/Autocomplete2';
import { notify } from 'reducers/NotifierReducer';
import { handleError } from 'reducers/ErrorReducer';
import { getAgency, editAgency } from 'Settings/Agencies/AgencyFunctions';
import FormDivider from 'components/FormDivider';
import { notifyDataUpdate } from 'reducers/DataUpdateReducer';


const useStyles = makeStyles((theme) => ({
  ...formStyles,
  wrap: {
    padding: theme.spacing(3),
  },
  item: gridStyle(200, 800),
  field: gridStyle(210, 400),
  actions: {
    textAlign: 'right',
    marginTop: 8,
    '& button': {
      marginLeft: 8,
      '& svg': {
        marginRight: theme.spacing(1),
      },
    },
  },
}));


function AgencyTab(props)  {
  const classes = useStyles();
  const { agencyId, dictionary } = props;
  const { PlaceTypes } = dictionary;
  const [Description, setDescription] = useState('');
  const [Place, setPlace] = useState(null);
  const [PlaceName, setPlaceName] = useState('');
  const [PlaceORI, setPlaceORI] = useState('');
  const [PlaceDepartmentID, setPlaceDepartmentID] = useState('');
  const [PlaceType, setPlaceType] = useState(null);
  const [isPolice, setIsPolice] = useState(false);
  const [isEms, setIsEms] = useState(false);
  const [isFire, setIsFire] = useState(false);
  const [ptsPlaceID, setPtsPlaceID] = useState(null);

  useEffect(() => {
    const search = async () => {
      const data = await getAgency(agencyId);
      initData(data);
    }
    search();
  }, []);

  const initData = (data) => {
    const { Description, EMS, Fire, PlaceDepartmentID, 
      ptsPlaceID, PlaceName, PlaceORI, PlaceType, Police } = data;
    setDescription(Description || '');
    setPtsPlaceID(ptsPlaceID);
    setPlaceName(PlaceName || '');
    setPlaceORI(PlaceORI || '');
    setPlaceType(PlaceTypes?.find(type => type.Code === PlaceType) || null);
    setIsPolice(Police === 1);
    setIsEms(EMS === 1);
    setIsFire(Fire === 1);
    setPlaceDepartmentID(PlaceDepartmentID || '');
  }

  const handleSaveClick = async () => {
    const saveObj = formatSaveData({
      Description,
      PlaceName,
      PlaceORI,
      PlaceType,
      PlaceDepartmentID
    });
    saveObj.AgencyType = calAgencyType();
    saveObj.ptsPlaceID = Place?.ptsPlaceID || null;
    try {
      await editAgency(agencyId, saveObj);  
      props.notifyDataUpdate({ type: 'agencies' });
      props.notify("Agency Updated!", "success");
    }
    catch (err) {
      props.handleError(err, 'Error Updating Agency');
    }
  }

  const calAgencyType = () => {
    let out = 0;
    if (isPolice) out += 1;
    if (isFire) out += 2;
    if (isEms) out += 4;
    return out;
  }

  const renderAgencyID = () => {
    return (
      <TextField
        label="Agency ID"
        variant="outlined"
        value={agencyId}
        size="small"
        className={classes.item}
        disabled
      />
    );
  }

  const renderDescription = () => {
    return (
      <TextField
        label="Description"
        variant="outlined"
        onChange={(e) => setDescription(e.target.value)}
        value={Description}
        size="small"
        className={classes.item}
      />
    );
  }

  const renderPlaceName = () => {
    return (
      <TextField
        label="Place Name"
        variant="outlined"
        onChange={(e) => setPlaceName(e.target.value)}
        value={PlaceName}
        size="small"
        className={classes.item}
      />
    );
  }

  const renderPlaceOri = () => {
    return (
      <TextField
        label="Place ORI"
        variant="outlined"
        onChange={(e) => setPlaceORI(e.target.value)}
        value={PlaceORI}
        size="small"
        className={classes.item}
      />
    );
  }

  const renderPlaceDeptID = () => {
    return (
      <TextField
        label="Place Department ID"
        variant="outlined"
        onChange={(e) => setPlaceDepartmentID(e.target.value)}
        value={PlaceDepartmentID}
        size="small"
        className={classes.item}
      />
    );
  }

  const renderPlace = () => {
    const handleChange = (value) => setPlace(value);
    return (
      <PlaceLookup
        onChange={handleChange}
        className={classes.item}
        label="Place"
        ptsPlaceID={ptsPlaceID}
      />
    );
  }

  const renderPlaceType = () => {
    return (
      <Autocomplete2
        options={PlaceTypes}
        className={classes.item}
        onChange={(ev, val) => setPlaceType(val)}
        value={PlaceType}
        label="Place Type"
      />
    )
  }

  const renderAgencyType = () => {
    return (
      <div>
        <FormDivider title='Agency Type' />
        <FormControlLabel
          control={
            <Checkbox
              checked={isPolice}
              onChange={() => setIsPolice(!isPolice)}
              color="primary"
            />
          }
          label="Police"
          className={classes.item}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isEms}
              onChange={() => setIsEms(!isEms)}
              color="primary"
            />
          }
          label="EMS"
          className={classes.item}
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={isFire}
              onChange={() => setIsFire(!isFire)}
              color="primary"
            />
          }
          label="Fire"
          className={classes.item}
        />
      </div>
    );
  }

  const renderSaveBtn = () => {
    return (
      <Button 
        onClick={handleSaveClick} 
        variant="contained" 
        className="mt-4"
        disabled={!Place}
        color="primary">
        <SaveIcon className="mr-2" /> Save 
      </Button> 
    )
  }

  return (
    <>
    <Row>
      <ColCard minWidth={500}>
        {renderAgencyID()}
        {renderDescription()}
        {renderAgencyType()}
      </ColCard>
      <ColCard minWidth={500}>
        {renderPlace()}
        {renderPlaceName()}
        {renderPlaceOri()}
        {renderPlaceDeptID()}
        {renderPlaceType()}
      </ColCard>
      </Row>
      {renderSaveBtn()}
    </>
  )
}
 

const mapStateToProps = state => {
  return {
    dictionary: state.dictionary
  }
}

export default connect(mapStateToProps, {
  handleError, notify, notifyDataUpdate, 
})(AgencyTab);