import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PersonLookup from 'components/PersonLookup';
import formStyles, { gridStyle, Row, RowInner, ColCard, Fills, formatSaveData } from 'utils/formStyles';
import { findSearchProfiles, findSearchClearanceLevels } from 'Settings/Security/SecFunctions';
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles(theme => ({
  item: gridStyle(200, 320),
  autoComplete: gridStyle(200, 300)
}));

function AddUser(props) {
  const classes = useStyles();
  const [username, setUsername] = useState('');
  const [person, setPerson] = useState(null);
  const [profile, setProfile] = useState(null);
  const [clearance, setClearance] = useState(null);
  const [email, setEmail] = useState('');
  const [allProfiles, setAllProfiles] = useState([]);
  const [allClearanceLevels, setAllClearanceLevels] = useState([]);

  useEffect(() => {
    fetchProfiles();
    fetchClearanceLevels();
  }, []);

  const fetchProfiles = async () => {
    const result = await findSearchProfiles();
    setAllProfiles(result);
  }

  const fetchClearanceLevels = async () => {
    const result = await findSearchClearanceLevels();
    setAllClearanceLevels(result);
  }

  const renderUsername = () => {
    return (
      <TextField 
        label="Username"
        variant="outlined"
        value={username}
        size="small"
        onChange={(e) => setUsername(e.target.value)}
        fullWidth
        className={classes.item}
      />
    )
  }

  const renderEmail = () => {
    return (
      <TextField 
        label="Email"
        variant="outlined"
        value={email}
        size="small"
        onChange={(e) => setEmail(e.target.value)}
        fullWidth
        className={classes.item}
      />
    )
  }

  const renderPersonLookup = () => {
    return(
      <PersonLookup
        className={classes.item}
        onPersonChange={setPerson}
        label="Person Lookup"
      />
    )
  }

  const renderProfileLookup = () => {
    const handleChange = (e, newValue) => setProfile(newValue);
    return (
      <Autocomplete
        disablePortal
        options={allProfiles}
        className={classes.item}
        renderInput={(params) => <TextField {...params} label="Profile Lookup" variant="outlined" />}
        onChange={handleChange}
        getOptionLabel={(option) => option.Label}
        renderOption={option => `${option.Label} ${option.Type ? '- ' + option.Type : ''}`}
        getOptionSelected={(option, value) => option.ptsProfileID === value.ptsProfileID}
        size="small"
        value={profile}
      />
    )
  }

  const renderClearanceLevel = () => {
    const handleChange = (e, newValue) => setClearance(newValue);
    return (
      <Autocomplete
        disablePortal
        options={allClearanceLevels}
        className={classes.item}
        renderInput={(params) => <TextField {...params} label="Clearance Levels Lookup" variant="outlined" />}
        onChange={handleChange}
        getOptionLabel={(option) => option.Level}
        renderOption={option => `${option.Level} ${option.Description ? '- ' + option.Description : ''}`}
        size="small"
        value={clearance}
      />
    )
  }

  return (
    <div>
      {renderUsername()}
      {renderPersonLookup()}
      {renderProfileLookup()}
      {renderClearanceLevel()}
      {renderEmail()}
    </div>
  )
}

export default AddUser;