import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from 'components/Dialog';
import { closeAddSecUser } from 'reducers/DialogsReducer';
import { Button } from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CloseIcon from '@material-ui/icons/Close';
import AddUser from './AddUser';

const useStyles = makeStyles(theme => ({

}));

const AddSecUserDialog = (props) => {
  const classes = useStyles();

  const close = () => {
    props.closeAddSecUser();
  }

  const renderActions = () => {
    return (
      <>
        <Button color="primary" variant="contained" autoFocus onClick={close} >
          <SaveIcon /> Save
        </Button>
        <Button color="primary" onClick={close}>
          <CloseIcon /> Close
        </Button>
      </>
    );
  };

  return (
    <Dialog 
      toolbar 
      onClose={close} 
      title="Add User" 
      actions={renderActions()}
      maxWidth='md'
      fullWidth
    >
      <AddUser />
    </Dialog>
  )
}


const mapStateToProps = (state) => {
  return {
    dictionary: state.dictionary
  }
}

export default connect(mapStateToProps, {  closeAddSecUser, })(AddSecUserDialog);